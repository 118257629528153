/* eslint-disable indent */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState, useEffect } from 'react';
import appConfig from 'config/app.config';
import { challengeUiTexts } from 'data/ui-texts';
import { streakPoints } from 'data/points-data';
import { getIconData } from 'helpers/points-helper';
import BackButton from 'components/layout/back-button';
import Popup from 'components/layout/popup';
import { IPlayerData, IChallenge } from 'components/game/game-controller';
import QuizController from './quiz/quiz-controller';
import PairsController from './pairs/pairs-controller';
import SortController from './sort/sort-controller';
import OrderController from './order/order-controller';
import { IGState, useGlobal } from 'components/game/game-controller';
import './challenge.scss';
import { IResolveStatus } from './challenge-controller';

interface IProps {
	challengeCompleted: boolean;
	isLastChallengeInRoom: boolean;
	showChallengeCompletedPopup: boolean;
	showNewPointsTierPopup: boolean;
	showStreakPopup: boolean;
	roomId: number;
	challengeIndex: number;
	roomChallengesData: IChallenge[];
	playerData: IPlayerData;
	goToPage: (pageId: string | null, roomId?: number | null) => void;
	goToPreviousPage: () => void;
	goToChallenge: (challengeIndex: number) => void;
	goToNextChallenge?: (() => void) | null;
	toggleStreakPopup: (
		showStreakPopup: boolean,
		challengeCompleted?: boolean,
		isLastChallengeInRoom?: boolean,
		newPointsTierUnlocked?: boolean
	) => void;
	closeNewPointsTierPopup: () => void;
	handleRoomComplete: (roomStreakUnlocked: boolean) => void;
	completeChallenge: (
		challengeId: string,
		points: number,
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		playerChallenges: any,
		playerStreaks: any,
		playerpoints: any
	) => Promise<null>;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
}
const Challenge: React.FC<IProps> = ({
	challengeCompleted,
	isLastChallengeInRoom,
	showChallengeCompletedPopup,
	showNewPointsTierPopup,
	showStreakPopup,
	roomId,
	challengeIndex,
	roomChallengesData,
	playerData,
	goToPage,
	goToPreviousPage,
	toggleStreakPopup,
	closeNewPointsTierPopup,
	completeChallenge,
	updatePlayerData,
	goToChallenge,
	goToNextChallenge,
	handleRoomComplete,
}: IProps) => {
	interface ComponentProps {
		roomId: number;
		challengeData: IChallenge;
		playerData: IPlayerData;
		toggleStreakPopup: (
			showStreakPopup: boolean,
			challengeCompleted?: boolean | undefined,
			isLastChallengeInRoom?: boolean | undefined,
			newPointsTierUnlocked?: boolean | undefined
		) => void;
		completeChallenge: (
			challengeId: string,
			points: number,
			streakUnlocked: boolean,
			newPointsTierUnlocked: boolean,
			playerChallenges: any,
			playerStreaks: any,
			playerpoints: any
		) => Promise<null>;
		updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
	}

	const props: ComponentProps = {
		roomId: roomId,
		challengeData: roomChallengesData[challengeIndex],
		playerData: playerData,
		toggleStreakPopup: toggleStreakPopup,
		completeChallenge: completeChallenge,
		updatePlayerData: updatePlayerData,
	};
	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [challengeUiTextsLang, setChallengeUiTextsLang] = React.useState({...challengeUiTexts.dk});
	React.useEffect(() => {
		setChallengeUiTextsLang(currentlang === 'en' ? challengeUiTexts.en : challengeUiTexts.dk );
	}, [currentlang]);

	const [points, setPoints] = useState(0);
	useEffect(() => {
		let totalPoints;
		if (playerData.hasOwnProperty('points')) {
			totalPoints = JSON.parse(JSON.stringify(playerData.points));
		}

		if (!totalPoints.hasOwnProperty('room' + roomId.toString())) {
			totalPoints['room' + roomId.toString()] = 0;
		}
		if (totalPoints['room' + roomId.toString()]) {
			setPoints(totalPoints['room' + roomId.toString()]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showNewPointsTierPopup]);

	/* Prepare new point tier popup text */
	let newPointTierPopupTitle = '';
	let newPointTierPopupText = '';
	let newPointTierPopupIconData = null;
	if (showNewPointsTierPopup) {
		newPointTierPopupTitle = challengeUiTextsLang.newPointTierPopup.title;
		newPointTierPopupText = challengeUiTextsLang.newPointTierPopup.text;
		newPointTierPopupIconData = getIconData(points, roomId);
	}

	/* Prepare streak popup text */
	let streakPopupTitle = '';
	let streakPopupText = '';
	let streakPopupPoints = 0;
	let streakPopupIconData = null;

	if (showStreakPopup) {
		const correctAnswers = streakPoints.streakMarker;
		streakPopupPoints = streakPoints.streakPoints;

		if (!showNewPointsTierPopup) {
			streakPopupTitle = challengeUiTextsLang.streakPopup.title;
			streakPopupText = JSON.parse(
				JSON.stringify(challengeUiTextsLang.streakPopup.text)
			).replace(/%answers%/g, correctAnswers);
		} else {
			streakPopupTitle = challengeUiTextsLang.streakPopup.title2;
			streakPopupText = JSON.parse(
				JSON.stringify(challengeUiTextsLang.streakPopup.text2)
			)
				.replace(/%answers%/g, correctAnswers)
				.replace(/%points%/g, streakPopupPoints);
			streakPopupIconData = getIconData(points, roomId);
		}
	}

	const DefaultComponent: React.FC<ComponentProps> = ({
		challengeData,
	}: ComponentProps) => {
		return <div>Unknown challenge: {challengeData.type}</div>;
	};

	let ChallengeComponent: React.FC<ComponentProps>;
	switch (roomChallengesData[challengeIndex].type) {
		case 'quiz':
			ChallengeComponent = QuizController;
			break;
		case 'pairs':
			ChallengeComponent = PairsController;
			break;
		case 'sort':
			ChallengeComponent = SortController;
			break;
		case 'order':
			ChallengeComponent = OrderController;
			break;
		default:
			ChallengeComponent = DefaultComponent;
			break;
	}

	return (
		<div
			className={
				'Challenge Challenge--' + roomChallengesData[challengeIndex].type
			}
		>
			{/* Challenge header */}
			<div className="Challenge-header">
				<BackButton roomId={roomId} goToPreviousPage={goToPreviousPage} />
				<div className="Challenge-nav">
					{roomChallengesData.map((challenge, index) => {
						let className =
							'Challenge-challengeStatus Challenge-challengeStatus--' +
							challenge.status;
						if (index === challengeIndex) {
							className =
								className + ' Challenge-challengeStatus--selected';
						}
						let canNavigateTo = false;
						if (
							appConfig.env === 'development' ||
							appConfig.env === 'test'
						) {
							canNavigateTo = true;
						}
						return (
							<div
								key={index}
								className={className}
								onClick={() => {
									if (canNavigateTo) goToChallenge(index);
								}}
							>
								§
							</div>
						);
					})}
				</div>
			</div>

			{/* Challenge body */}
			<div className="Challenge-body">
				<ChallengeComponent {...props} />
			</div>

			{/* Challenge completed popup */}
			<Popup
				showPopup={showChallengeCompletedPopup && !showNewPointsTierPopup}
				type="challengeCompleted"
				buttons={[
					{
						text: challengeUiTextsLang.next,
						action: isLastChallengeInRoom
							? handleRoomComplete
							: goToNextChallenge,
						params: [],
					},
				]}
			/>

			{/* Streak popup */}
			<Popup
				showPopup={showStreakPopup && showNewPointsTierPopup}
				type="streak"
				title={streakPopupTitle}
				text={streakPopupText}
				iconData={streakPopupIconData}
				points={streakPopupPoints}
				buttons={[
					{
						text: challengeUiTextsLang.streakPopup.buttons.ok,
						action: toggleStreakPopup,
						params: [false, challengeCompleted, isLastChallengeInRoom],
					},
				]}
			/>

			{/* New points tier popup */}
			<Popup
				showPopup={showNewPointsTierPopup && !showStreakPopup}
				type="newPointsTier"
				title={newPointTierPopupTitle}
				text={newPointTierPopupText}
				iconData={newPointTierPopupIconData}
				buttons={[
					{
						text: challengeUiTextsLang.newPointTierPopup.buttons.ok,
						action: closeNewPointsTierPopup,
						params: [isLastChallengeInRoom],
					},
				]}
			/>
		</div>
	);
};

export default Challenge;
