const cookieUiTexts = {
	text: 'Spillet bruger cookies til at gemme spilresultatet.<br />Godkend spillets brug af cookies.',
	okBtn: 'Ok',
};

const errorUiTexts = {
	dk:{
	offline: 'Du er offline. Forbind til internettet for at spille.',
	},
	en: {
		offline: 'You are offline. Connect to the Internet to play.'
	}
};

const generalUiTexts = {
	dk: {
		gameName: 'Domstolsdysten',
		availabilityStatement: 'Tilgængelighedserklæring',
		introText:
			'Hvad ved du om det retssamfund, der er et fundament for vores demokrati? Test dig selv i de fire quizzer og se, om du er novice eller stormester.',
		startGame: 'Start Domstolsdysten',
		otherGameText: 'Vil du udfordres yderligere? Så prøv vores nye spil om Grundloven her:',
		otherGameTitle: 'Gå til Grundlovsdysten',
		moreInfo: 'Mere info...',
		aboutTitle: 'KORT OM QUIZZEN',
		developedBy: 'Domstolsdysten er udviklet af Domstolsstyrelsen og Copenhagen Game Lab.',
		copyright: 'Copyright 2020: Domstolsstyrelsen',
		yourScore: 'Jeres score',
		points: 'point',
		ok: 'Ok',
	},
	en: {
		gameName: 'Court battle',
		availabilityStatement: 'Availability statement',
		introText: 'What do you know about the Rule of Law as an essential foundation for our democracy? Try our quizzes and find out if you are a novice or a wizard? ',
		startGame: 'Start Court battle',
		otherGameText: 'Vil du udfordres yderligere? Så prøv vores nye spil om Grundloven her:',
		otherGameTitle: 'Go to Grundlovsdysten',
		moreInfo: 'More info...',
		aboutTitle: 'ABOUT THE GAME',
		developedBy: 'The Court battle game was developed by the Domstolsstyrelsen and the Copenhagen Game Lab.',
		copyright: 'Copyright 2020: Domstolsstyrelsen',
		yourScore: 'Your score',
		points: 'points',
		ok: 'Ok',
	}
};

const challengeUiTexts = {
	dk: {
		noChallenges: 'Der er ingen opgaver i område %roomId% endnu.',
		choose: 'Vælg',
		answers: 'svar',
		next: 'Næste',
		streakPopup: {
			title: 'Sådan!',
			text: '%answers% rigtige svar i træk:',
			title2: 'Godt gået!',
			text2:
				'<span class="red">%answers%</span> rigtige svar i træk: <span class="red">+%points% point</span>.<br />Og du har nu fået point nok til at blive',
			buttons: { ok: 'Videre' },
		},
		newPointTierPopup: {
			title: 'Godt gået!',
			text: 'Du har nu fået point nok til at blive',
			buttons: { ok: 'Ok' },
		},
		roomCompletedPopup: {
			title: [
				'Resultat: %points% ud af 89 point!',
				'Resultat: %points% ud af 97 point!',
				'Resultat: %points% ud af 128 point!',
				'Result: %points% points out of 113 points!' ,
				'Result: %points% points out of 100 points!' ,
				'Resultat: %points% ud af 100 point!',
			],
			texts: [
				'I kategorien <strong>Ret og retfærdighed</strong> fik du point nok til at blive:',
				'I kategorien <strong>Rettens arbejde</strong> fik du point nok til at blive:',
				'I kategorien <strong>Det europæiske perspektiv</strong> fik du point nok til at blive:',
				'In the category <strong>For law and justice</strong> you obtained enough points to become a:',
				'In the category <strong>Corruption</strong> you obtained enough points to become a:',
				'I kategorien <strong>Korruption</strong> fik du point nok til at blive:',
			],
			shareTexts: [
				'Jeg spillede Domstolsdysten om ret og retfærdighed og fik point nok til at blive:',
				'Jeg spillede Domstolsdysten om rettens arbejde og fik point nok til at blive:',
				'Jeg spillede Domstolsdysten om det europæiske perspektiv og fik point nok til at blive:',
				'I played The Court Battle about law and justice and scored enough points to become a:',
				'I played The Court Battle about corruption and scored enough points to become a:',
				'Jeg spillede Domstolsdysten om korruption og fik point nok til at blive:',
			],
		},
	},
	en: {
		noChallenges: 'There are no tasks in area %roomId% yet.',
		choose: 'Choose',
		answers: 'answers',
		next: 'Next',
		streakPopup: {
			title: 'Yeah!',
			text: '%answers% correct answers in a row:',
			title2: 'Well done!',
			text2:
				'<span class="red">%answers%</span> correct answers in a row: <span class="red">+%points% points</span>.<br />And you have scored enough points to become a',
			buttons: { ok: 'Continue' },
		},
		newPointTierPopup: {
			title: 'Well done!',
			text: 'You obtained enough points to become a',
			buttons: { ok: 'Ok' },
		},
		roomCompletedPopup: {
			title: [
				'Resultat: %points% ud af 89 point!',
				'Resultat: %points% ud af 97 point!',
				'Resultat: %points% ud af 128 point!',
				'Result: %points% points out of 113 points!' ,
				'Result: %points% points out of 100 points!' ,
				'Resultat: %points% ud af 100 point!',
			],
			texts: [
				'I kategorien <strong>Ret og retfærdighed</strong> fik du point nok til at blive:',
				'I kategorien <strong>Rettens arbejde</strong> fik du point nok til at blive:',
				'I kategorien <strong>Det europæiske perspektiv</strong> fik du point nok til at blive:',
				'In the category <strong>For law and justice</strong> you obtained enough points to become a:',
				'In the category <strong>Corruption</strong> you obtained enough points to become a:',
				'I kategorien <strong>Korruption</strong> fik du point nok til at blive:',
			],
			shareTexts: [
				'Jeg spillede Domstolsdysten om ret og retfærdighed og fik point nok til at blive:',
				'Jeg spillede Domstolsdysten om rettens arbejde og fik point nok til at blive:',
				'Jeg spillede Domstolsdysten om det europæiske perspektiv og fik point nok til at blive:',
				'I played The Court Battle about law and justice and scored enough points to become a:',
				'I played The Court Battle about corruption and scored enough points to become a:',
				'Jeg spillede Domstolsdysten om korruption og fik point nok til at blive:',
			],
		},
	}
};

const loginUiTexts = {
	loginTitle: 'Log på som admin',
	email: 'e-mail',
	password: 'password',
	login: 'Log ind',
	logout: 'Log ud',
	unknownFejl: 'Fejl: ukendt fejl',
	someFieldsMissing: 'Fejl: Husk at udfylde alle felter',
	invalidEmail: 'Fejl: Ugyldig e-mail',
	invalidPassword: 'Fejl: Forkert password',
	userNotFound: 'Fejl: Ukendt bruger'
};

export { cookieUiTexts, errorUiTexts, generalUiTexts, challengeUiTexts, loginUiTexts };
