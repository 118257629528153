/* TEMPLATE
{
	id: '',
	type: 'quiz',
	roomId: 1,
	challengeNumber: 1,
	question: {
		text: '',
		showNumberOfAnswers: true,
		image: null,
		showImageInPopup: false
	},
	answers: [
		{id: 1, text: '', isSelected: false, isCorrect: false},
		{id: 2, text: '', isSelected: false, isCorrect: false},
		{id: 3, text: '', isSelected: false, isCorrect: false}
	]
},
*/
export interface IQuizQuestion {
	text: string;
	showNumberOfAnswers: boolean;
	image: null;
	showImageInPopup: boolean;
}
export interface IQuizAnswer {
	id: number;
	text: string;
	isSelected: boolean;
	isCorrect: boolean;
}
export interface IQuizData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IQuizQuestion;
	answers: IQuizAnswer[];
	completed?: boolean;
}

const quizzesData: IQuizData[] = [
	{
		id: 'quiz-1',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 2,
		question: {
			text: 'Hvad gælder i det danske retssystem?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{
				id: 1,
				text: 'Du må højst være anholdt i 24 timer, før du skal for en dommer',
				isSelected: false,
				isCorrect: true,
			},
			{ id: 2, text: 'Som sigtet har du ret til at vælge en forsvarer', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Politiet dømmer, om folk er skyldige eller uskyldige', isSelected: false, isCorrect: false },
			{
				id: 4,
				text: 'Du må højst være fængslet 36 timer, før du skal for en dommer',
				isSelected: false,
				isCorrect: false,
			},
			{ id: 5, text: 'Som sigtet eller tiltalt i en retssag må du gerne lyve', isSelected: false, isCorrect: true },
		],
	},
	{
		id: 'quiz-2',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 5,
		question: {
			text: "Som udgangspunkt er retssager offentlige. Hvad kan være begrundelser for at 'lukke dørene'?",
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Hensynet til personer under 18 år', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Hensyn til sagens opklaring', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Hensyn til erhvervshemmeligheder', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Fordi både tiltalte og forurettede ønsker det', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Hvis tiltalte er en offentligt kendt person', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-3',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 6,
		question: {
			text: 'Hvis dommeren vurderer, at dørene skal lukkes til en retssag, hvem skal så høres i den forbindelse?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Anklager', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Forsvarer', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Tilhørere', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Politiet', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Journalister', isSelected: false, isCorrect: true },
			{ id: 6, text: 'Den sigtede eller tiltaltes familie', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-4',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 7,
		question: {
			text: 'Hvorfor rejser man sig op, når dommeren kommer ind i retssalen?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'For at vise respekt for retten ', isSelected: false, isCorrect: true },
			{ id: 2, text: 'For at vise respekt for loven', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Fordi dommeren godt kan lide det', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Fordi det står i loven', isSelected: false, isCorrect: false },
			{ id: 5, text: 'For at vise, at man ikke bærer våben', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-5',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 8,
		question: {
			text: 'Hvad er rigtigt om vidner?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Man skal vidne, selvom det handler om ens tætte familie', isSelected: false, isCorrect: false },
			{
				id: 2,
				text: 'Man har sædvanligvis ret til godtgørelse, når man er vidne i retten',
				isSelected: false,
				isCorrect: true,
			},
			{ id: 3, text: 'Man skal vidne, selvom det, man siger, kan skade en selv', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Man kan selv bestemme, om man vil være et anonymt vidne', isSelected: false, isCorrect: false },
			{
				id: 5,
				text: 'Man har pligt til at møde i retten, hvis man bliver indkaldt som vidne',
				isSelected: false,
				isCorrect: true,
			},
		],
	},
	{
		id: 'quiz-6',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 9,
		question: {
			text: 'Hvad er rigtigt om ytringsfrihed?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Det er ulovligt at ytre sig racistisk og opfordre til terror', isSelected: false, isCorrect: true },
			{
				id: 2,
				text: 'Man må altid sige, hvad man vil, på sit arbejde og om sit arbejde',
				isSelected: false,
				isCorrect: false,
			},
			{
				id: 3,
				text: 'Man må normalt gerne kritisere politikere uden at blive fyret',
				isSelected: false,
				isCorrect: true,
			},
			{
				id: 4,
				text: 'Man må normalt gerne kritisere politiet uden at blive sat i fængsel',
				isSelected: false,
				isCorrect: true,
			},
			{ id: 5, text: 'Man har krav på at få læserbreve i aviserne', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-7',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 11,
		question: {
			text: 'Hvad er et grundlovsforhør?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'En test i folkeskolen om grundloven', isSelected: false, isCorrect: false },
			{
				id: 2,
				text: 'Et retsmøde, hvor en dommer beslutter, om der kan ske varetægtsfængsling',
				isSelected: false,
				isCorrect: true,
			},
			{
				id: 3,
				text: 'En retssag om, hvorvidt en lov er i overensstemmelse med grundloven',
				isSelected: false,
				isCorrect: false,
			},
			{
				id: 4,
				text: 'En afhøring, hvor en sigtet spørges om sit kendskab til grundloven',
				isSelected: false,
				isCorrect: false,
			},
			{ id: 5, text: 'En eksamen for anklagere og forsvarere', isSelected: false, isCorrect: false },
		],
	},
	// {
	// 	id: 'quiz-8',
	// 	type: 'quiz',
	// 	roomId: 1,
	// 	challengeNumber: 12,
	// 	question: {
	// 		text: 'Hvad er rigtigt om varetægtsfængsling?',
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Man er fængslet under efterforskningen - altså før man er dømt', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'En dommer tager stilling til, om betingelserne for varetægtsfængsling er opfyldt', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'Politiet tager vare på fx en beruset person, som overnatter i detentionen', isSelected: false, isCorrect: false},
	// 		{id: 4, text: 'Retsmødet hvor man tager stilling til varetægtsfængsling kaldes et grundlovsforhør', isSelected: false, isCorrect: true},
	// 		{id: 5, text: 'Man skal stilles for en dommer inden 24 timer efter anholdelsen', isSelected: false, isCorrect: true},
	// 		{id: 6, text: 'Varetægtsfængsling er en særlig form for samfundstjeneste', isSelected: false, isCorrect: false}
	// 	]
	// },
	// {
	// 	id: 'quiz-9',
	// 	type: 'quiz',
	// 	roomId: 1,
	// 	challengeNumber: 13,
	// 	question: {
	// 		text: 'Hvad er den korteste fængselsstraf man kan få?',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: '1 dag', isSelected: false, isCorrect: false},
	// 		{id: 2, text: '3 dage', isSelected: false, isCorrect: false},
	// 		{id: 3, text: '7 dage', isSelected: false, isCorrect: true},
	// 		{id: 4, text: '10 dage', isSelected: false, isCorrect: false},
	// 		{id: 5, text: '14 dage ', isSelected: false, isCorrect: false},
	// 		{id: 6, text: '30 dage', isSelected: false, isCorrect: false}
	// 	]
	// },
	// {
	// 	id: 'quiz-10',
	// 	type: 'quiz',
	// 	roomId: 1,
	// 	challengeNumber: 14,
	// 	question: {
	// 		text: 'Hvor lang tid skal man afsone, hvis man får en livstidsdom?',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Livstid er livstid, men man kan søge om benådning efter 16 år', isSelected: false, isCorrect: true},
	// 		{id: 2, text: '16 år', isSelected: false, isCorrect: false},
	// 		{id: 3, text: '20 år', isSelected: false, isCorrect: false},
	// 		{id: 4, text: '30 år ', isSelected: false, isCorrect: false},
	// 		{id: 5, text: '50 år', isSelected: false, isCorrect: false}
	// 	]
	// },
	// {
	// 	id: 'quiz-11',
	// 	type: 'quiz',
	// 	roomId: 2,
	// 	challengeNumber: 3,
	// 	question: {
	// 		text: 'Skifteretten kan hjælpe dig med følgende sagstyper.',
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Sager om børnebidrag', isSelected: false, isCorrect: false},
	// 		{id: 2, text: 'Behandling af dødsboer', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'Tvangsopløsning af selskaber', isSelected: false, isCorrect: true},
	// 		{id: 4, text: 'Gældssanering af privatpersoner', isSelected: false, isCorrect: true},
	// 		{id: 5, text: 'Fremtidsfuldmagter', isSelected: false, isCorrect: false},
	// 	]
	// },
	{
		id: 'quiz-12',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 5,
		question: {
			text: 'Hvilke sager kan betegnes som civile sager?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Sager mellem to borgere', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Sager, hvor man kan blive idømt en fængselsstraf', isSelected: false, isCorrect: false },
			{ id: 3, text: 'Uenighed mellem lejer og udlejer om husleje', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Sager om gældssanering', isSelected: false, isCorrect: false },
		],
	},
	// {
	// 	id: 'quiz-13',
	// 	type: 'quiz',
	// 	roomId: 2,
	// 	challengeNumber: 6,
	// 	question: {
	// 		text: 'Hvad kan du få hjælp med hos notaren?',
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Fremtidsfuldmagt', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'Udtrækning af vindere af større lodtrækninger', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'Åbning af bankbokse', isSelected: false, isCorrect: true},
	// 		{id: 4, text: 'Registrering af ejerskab over en bolig', isSelected: false, isCorrect: false},
	// 		{id: 5, text: 'Bekræftelse af kopier', isSelected: false, isCorrect: true},
	// 		{id: 6, text: 'Få lavet syn og skøn', isSelected: false, isCorrect: false},
	// 	]
	// },
	{
		id: 'quiz-14',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 7,
		question: {
			text: 'Hvad gælder, hvis du laver et testamente?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{
				id: 1,
				text: 'Arvingerne skal betale din gæld, hvis der ikke er penge nok i boet til det',
				isSelected: false,
				isCorrect: false,
			},
			{ id: 2, text: 'Et testamente SKAL påtegnes af en notar for at være gyldigt', isSelected: false, isCorrect: false },
			{
				id: 3,
				text: 'Du kan bestemme, at arven efter dig ikke skal deles, hvis din arving bliver skilt',
				isSelected: false,
				isCorrect: true,
			},
		],
	},
	{
		id: 'quiz-15',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 8,
		question: {
			text: 'Hvad er rigtigt for en fremtidsfuldmagt?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Den træder i kraft ved opråb på offentlig plads', isSelected: false, isCorrect: false },
			{
				id: 2,
				text: 'Du bestemmer, hvem du i fremtiden vil give fuldmagt til at varetage dine interesser',
				isSelected: false,
				isCorrect: true,
			},
			{
				id: 3,
				text: 'Den, der får fuldmagt, kan flytte dig på plejehjem imod dit ønske',
				isSelected: false,
				isCorrect: false,
			},
			{ id: 4, text: 'Den SKAL påtegnes af en notar for at være gyldig', isSelected: false, isCorrect: true },
			{ id: 5, text: 'Den, der får fuldmagten, bliver din enearving', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-16',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 10,
		question: {
			text: 'Hvad laver ungdomskriminalitetsnævnet?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{
				id: 1,
				text: 'Fastsætter sociale foranstaltninger for at hjælpe barnet ud af kriminalitet',
				isSelected: false,
				isCorrect: true,
			},
			{ id: 2, text: 'Kommer med forslag til kriminaltetsforebyggende aktiviteter', isSelected: false, isCorrect: false },
			{
				id: 3,
				text: 'Afgør sager, hvor børn ml. 10-17 år er mistænkt eller dømt for kriminalitet',
				isSelected: false,
				isCorrect: true,
			},
			{
				id: 4,
				text: 'Arbejder for at sikre, at der i medierne er fokus på ungdomskriminalitet ',
				isSelected: false,
				isCorrect: false,
			},
		],
	},
	{
		id: 'quiz-17',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 11,
		question: {
			text: 'Hvad er rigtigt om lægdommere?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Lægdommere omfatter både domsmænd og nævninger', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Lægdommere er med til at afgøre både skyld og straf', isSelected: false, isCorrect: true },
			{
				id: 3,
				text: 'Man kan kun få fri til at blive lægdommer, hvis arbejdsgiver tillader det',
				isSelected: false,
				isCorrect: false,
			},
			{ id: 4, text: 'Der medvirker lægdommere i de fleste danske straffesager, hvor der er spørgsmål om fængselsstraf', isSelected: false, isCorrect: true },
		],
	},
	// {
	// 	id: 'quiz-18',
	// 	type: 'quiz',
	// 	roomId: 2,
	// 	challengeNumber: 12,
	// 	question: {
	// 		text: 'Hvad er rigtigt i forhold til udvælgelsen af domsmænd?',
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Man er domsmand for en periode på 4 år', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'Man skal være medlem af et politisk parti for at blive domsmand', isSelected: false, isCorrect: false},
	// 		{id: 3, text: 'Alle danske statsborgere over 18 år kan søge om at blive domsmænd', isSelected: false, isCorrect: false},
	// 		{id: 4, text: 'En præst eller politimand kan ikke blive domsmand', isSelected: false, isCorrect: true},
	// 		{id: 5, text: 'Der er krav om, at der skal være en overvægt af kvindelige domsmænd i Danmark', isSelected: false, isCorrect: false}
	// 	]
	// },
	{
		id: 'quiz-19',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 13,
		question: {
			text: 'Hvad gælder for journalister i retten?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Journalister må altid liveblogge fra retssalen', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Journalister må gerne filme og fotografere fra retssalen', isSelected: false, isCorrect: false },
			{
				id: 3,
				text: 'Journalister har ekstra rettigheder for at kunne dække sagerne godt',
				isSelected: false,
				isCorrect: true,
			},
			{
				id: 4,
				text: 'Journalister kan kære kendelser om dørlukning, referatforbud og navneforbud',
				isSelected: false,
				isCorrect: true,
			},
		],
	},
	{
		id: 'quiz-20',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 15,
		question: {
			text: 'Hvad gælder i forhold til at anke en straffesagsdom fra byrettten?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Det er kun tiltalte, der kan anke', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Både anklager og tiltalte har mulighed for at anke dommen', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Man har en måned til at anke', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Man har som hovedregel 14 dage til at anke', isSelected: false, isCorrect: true },
		],
	},
	// {
	// 	id: 'quiz-21',
	// 	type: 'quiz',
	// 	roomId: 2,
	// 	challengeNumber: 17,
	// 	question: {
	// 		text: 'Hvad er en forvaringsdom?',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'En tidsubestemt fængselsstraf for særligt farlige kriminelle', isSelected: false, isCorrect: true},
	// 		{id: 2, text: 'En straf, hvor man afsoner på et psykiatrisk hospital', isSelected: false, isCorrect: false},
	// 		{id: 3, text: 'En slags samfundstjeneste', isSelected: false, isCorrect: false},
	// 		{id: 4, text: 'En foreløbig dom', isSelected: false, isCorrect: false},
	// 		{id: 5, text: 'En bøde som betales i afdrag over 10 år ', isSelected: false, isCorrect: false},
	// 		{id: 6, text: 'En advarsel om at du vil blive indkaldt til afsoning af en fængselsstraf', isSelected: false, isCorrect: false},
	// 		{id: 7, text: 'En straf hvor fx din bil konfiskeres og opbevares (forvares)', isSelected: false, isCorrect: false},
	// 	]
	// },
	// {
	// 	id: 'quiz-22',
	// 	type: 'quiz',
	// 	roomId: 3,
	// 	challengeNumber: 1,
	// 	question: {
	// 		text: 'Hvor ligger menneskerettighedsdomstolen?',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Luxembourg', isSelected: false, isCorrect: false},
	// 		{id: 2, text: 'Strasbourg', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'Bruxelles', isSelected: false, isCorrect: false},
	// 		{id: 4, text: 'Paris', isSelected: false, isCorrect: false},
	// 		{id: 5, text: 'Rom', isSelected: false, isCorrect: false},
	// 		{id: 6, text: 'Budapest', isSelected: false, isCorrect: false},
	// 		{id: 7, text: 'København', isSelected: false, isCorrect: false},
	// 		{id: 8, text: 'New York', isSelected: false, isCorrect: false}
	// 	]
	// },
	{
		id: 'quiz-23',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 2,
		question: {
			text: 'Hvem oprettede menneskerettighedsdomstolen?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'EU', isSelected: false, isCorrect: false },
			{ id: 2, text: 'FN', isSelected: false, isCorrect: false },
			{ id: 3, text: 'Europarådet', isSelected: false, isCorrect: true },
			{ id: 4, text: 'OSCE', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-24',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 3,
		question: {
			text: 'Hvad er rigtigt om menneskerettighedsdomstolen?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Den hører under EU', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Den ligger i Strasbourg', isSelected: false, isCorrect: true },
			{
				id: 3,
				text: 'Den behandler sager om Den Europæiske Menneskerettighedskonvention',
				isSelected: false,
				isCorrect: true,
			},
			{ id: 4, text: 'Den ligger i Oslo', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Den har 46 dommere &ndash; en fra hver medlemsstat', isSelected: false, isCorrect: true },
		],
	},
	{
		id: 'quiz-25',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 4,
		question: {
			text: 'Hvem kontrollerer, om en medlemsstat følger en dom fra menneskerettighedsdomstolen?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Højesteret i den pågældende medlemsstat', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Parlamentet eller folketinget i den pågældende medlemsstat', isSelected: false, isCorrect: false },
			{ id: 3, text: 'Europarådets ministerkomité', isSelected: false, isCorrect: true },
			{ id: 4, text: 'EU-Kommissionen', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-26',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 5,
		question: {
			text:
				'Hvilken menneskerettighed blev overtrådt, da en stat ikke havde gjort nok for at forhindre en methaneksplosion, der kostede ni mennesker livet?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 2, text: 'Artikel 2 &ndash; Retten til liv', isSelected: false, isCorrect: true },
			{
				id: 3,
				text: 'Artikel 8 &ndash; Respekt for retten til privat- og familieliv',
				isSelected: false,
				isCorrect: false,
			},
			{ id: 4, text: 'Artikel 3 &ndash; Forbud mod tortur', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Artikel 5 &ndash; Ret til frihed og sikkerhed', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-27',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 6,
		question: {
			text:
				'Hvilken menneskerettighed blev overtrådt, da en stat ikke havde oplyst nok om helbredsmæssige risici ved at bo tæt på en kemikalefabrik?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{
				id: 2,
				text: 'Artikel 8 &ndash; Respekt for retten til privat- og familieliv',
				isSelected: false,
				isCorrect: true,
			},
			{ id: 3, text: 'Artikel 2 &ndash; Retten til liv', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Artikel 3 &ndash; Forbud mod tortur', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Artikel 5 &ndash; Ret til frihed og sikkerhed', isSelected: false, isCorrect: false },
		],
	},
	// {
	// 	id: 'quiz-28',
	// 	type: 'quiz',
	// 	roomId: 3,
	// 	challengeNumber: 7,
	// 	question: {
	// 		text: 'Hvordan afgjorde menneskerettighedsdomstolen en sag, hvor en stat ikke havde stoppet alvorlig industriel forurening',
	// 		showNumberOfAnswers: false,
	// 		image: null,
	// 		showImageInPopup: false
	// 	},
	// 	answers: [
	// 		{id: 1, text: 'Ingen overtrædelse', isSelected: false, isCorrect: false},
	// 		{id: 2, text: 'Overtrædelse af artikel 8  - respekt for retten til privat- og familieliv', isSelected: false, isCorrect: true},
	// 		{id: 3, text: 'Overtrædelse af artikel 2 - Retten til liv', isSelected: false, isCorrect: false},
	// 		{id: 4, text: 'Overtrædelse artikel 3 - forbud mod tortur', isSelected: false, isCorrect: false},
	// 		{id: 5, text: 'Overtrædelse af artikel 5 - Ret til frihed og sikkerhed', isSelected: false, isCorrect: false},
	// 	]
	// },

	{
		id: 'quiz-28',
		type: 'quiz',
		roomId: 4,
		challengeNumber: 1,
		question: {
			text:'What is true of the European Court of Human Rights?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'It was established in 1959 by the Council of Europe', isSelected: false, isCorrect: true },
			{ id: 2, text: 'It has 46 judges – one from each member state', isSelected: false, isCorrect: true },
			{ id: 3, text: 'It treats cases under the European Convention on Human Rights', isSelected: false, isCorrect: true },
			{ id: 4, text: 'It belongs under the EU', isSelected: false, isCorrect: false },
			{ id: 5, text: 'It is situated in Oslo', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-29',
		type: 'quiz',
		roomId: 4,
		challengeNumber: 2,
		question: {
			text:'Who ensures that a member-state follows a judgement from the European Court of Human Rights?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'The Supreme Court in the member-state in question', isSelected: false, isCorrect: false },
			{ id: 2, text: 'The EU Commission', isSelected: false, isCorrect: false },
			{ id: 3, text: 'The Ombudsman', isSelected: false, isCorrect: false },
			{ id: 4, text: 'The Council of Europe’s Committee of Ministers', isSelected: false, isCorrect: true },
		],
	},
	{
		id: 'quiz-30',
		type: 'quiz',
		roomId: 4,
		challengeNumber: 3,
		question: {
			text:'How did the European Court of Human Rights judge a case where a state had not done enough to prevent a methane explosion that cost 9 human lives?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Violation of Article 2, Right to life', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Violation of Article 8, Respect for private and family life', isSelected: false, isCorrect: false },
			{ id: 3, text: 'Violation of Article 3, Prohibition of torture', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Violation of Article 5,	Right to liberty and security', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-31',
		type: 'quiz',
		roomId: 4,
		challengeNumber: 4,
		question: {
			text:'What applies for Danish lay judges?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'The lay judges decide together with the professional judge(s) on the sanction imposed', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Being a lay judge is a full time position', isSelected: false, isCorrect: false },
			{ id: 3, text: 'A lay judge decides together with the professional judge(s) if the accused person is guilty or not', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Lay judges participate in most criminal cases in Denmark', isSelected: false, isCorrect: true },
			{ id: 5, text: '80 % of all lay judges must be female', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-32',
		type: 'quiz',
		roomId: 4,
		challengeNumber: 5,
		question: {
			text:'Why do you stand up when the judge enters the courtroom in Denmark?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'To show your respect for the law', isSelected: false, isCorrect: true },
			{ id: 2, text: 'To show your respect for the court', isSelected: false, isCorrect: true },
			{ id: 3, text: 'It is an old tradition to show that you are not carrying weapons to court', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Because it is stated in the Danish constitution', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-33',
		type: 'quiz',
		roomId: 4,
		challengeNumber: 12,
		question: {
			text:'Prominent organs of the United Nations are?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'The Security Council', isSelected: false, isCorrect: true },
			{ id: 2, text: 'The International Court of Justice', isSelected: false, isCorrect: true },
			{ id: 3, text: 'The Intergalactic Court of Justice', isSelected: false, isCorrect: false },
			{ id: 4, text: 'The Arctic Council', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-34',
		type: 'quiz',
		roomId: 6,
		challengeNumber: 1,
		question: {
			text:'Hvordan defineres korruption ifølge Transparency International?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Uoverensstemmelse mellem etiske værdier', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Misbrug af betroet magt til egen privat vinding', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Tyveri af statslige midler', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Ugennemsigtighed i magthavernes handlen', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-35',
		type: 'quiz',
		roomId: 6,
		challengeNumber: 2,
		question: {
			text:'Hvilke konsekvenser kan korruption have i et samfund ifølge Europarådet?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Medvirker til at sikre fremgang i økonomien', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Undergraver befolkningens tillid til demokratiet', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Forhindrer social udvikling i samfundet', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Medvirker til øget lighed i samfundet', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Forhindrer økonomisk fremgang', isSelected: false, isCorrect: true },
		],
	},
	{
		id: 'quiz-36',
		type: 'quiz',
		roomId: 6,
		challengeNumber: 10,
		question: {
			text:'Hvordan fordeles straffesager blandt dommerne i retten?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Retspræsidenten træffer efter forhandling med rettens dommere bestemmelse om sagernes fordeling', isSelected: false, isCorrect: true },
			{ id: 2, text: 'Dommerne vælger selv de sager, som de synes er mest interessante', isSelected: false, isCorrect: false },
			{ id: 3, text: 'Tiltalte bestemmer hvilken dommer der skal behandle deres sag', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Hver dommer har én type forbrydelse, som de behandler', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-37',
		type: 'quiz',
		roomId: 6,
		challengeNumber: 8,
		question: {
			text:'Hvad betyder det, at der er åbenhed ved de danske domstole?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'At alle må være med til at stemme om resultatet', isSelected: false, isCorrect: false },
			{ id: 2, text: 'At der livestreames fra alle retssale i Danmark', isSelected: false, isCorrect: false },
			{ id: 3, text: 'At retsmøder som udgangspunkt er offentlige', isSelected: false, isCorrect: true },
			{ id: 4, text: 'At retsmøder som udgangspunkt er lukkede', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-38',
		type: 'quiz',
		roomId: 6,
		challengeNumber: 11,
		question: {
			text:'Hvad skal du gøre hvis du møder korruption?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Holde det for dig selv', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Sige det til din nærmeste leder', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Anmelde det til politiet', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Skrive et vredt læserbrev', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Undersøge om der er en whistleblowerordning', isSelected: false, isCorrect: true },
		],
	},
	{
		id: 'quiz-39',
		type: 'quiz',
		roomId: 6,
		challengeNumber: 7,
		question: {
			text:'Hvilke tiltag er med til at sikre Danmark mod korruption?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Høje cigaretpriser', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Kontrol af offentlig forvaltning', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Frie medier som fungerer som vagthunde', isSelected: false, isCorrect: true },
			{ id: 4, text: 'At vi yder humanitær hjælp', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Whistleblowerordninger', isSelected: false, isCorrect: true },
		],
	},
	// {
	// 	id: 'quiz-35',
	// 	type: 'quiz',
	// 	roomId: 6,
	// 	challengeNumber: 1,
	// 	question: {
	// 		text:'Prominent organs of the United Nations are?',
	// 		showNumberOfAnswers: true,
	// 		image: null,
	// 		showImageInPopup: false,
	// 	},
	// 	answers: [
	// 		{ id: 1, text: 'The Security Council', isSelected: false, isCorrect: true },
	// 		{ id: 2, text: 'The International Court of Justice', isSelected: false, isCorrect: true },
	// 		{ id: 3, text: 'The Intergalactic Court of Justice', isSelected: false, isCorrect: false },
	// 		{ id: 4, text: 'The Arctic Council', isSelected: false, isCorrect: false },
	// 	],
	// }
	{
		id: 'quiz-40',
		type: 'quiz',
		roomId: 5,
		challengeNumber: 1,
		question: {
			text: 'How is "corruption" defined by Transparency International?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Disagreement on ethical values', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Abuse of trust for private gain', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Theft of state property', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Lack of transparency in transactions by those in power', isSelected: false, isCorrect: false },
		],
	},
	{
		id: 'quiz-41',
		type: 'quiz',
		roomId: 5,
		challengeNumber: 2,
		question: {
			text:'What consequences can corruption have for a society according to the Council of Europe?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'It can help to ensure progress in the economy', isSelected: false, isCorrect: false },
			{ id: 2, text: 'It undermines the people’s confidence in democracy', isSelected: false, isCorrect: true },
			{ id: 3, text: 'It hinders social development', isSelected: false, isCorrect: true },
			{ id: 4, text: 'It contributes to increasing equality in society', isSelected: false, isCorrect: false },
			{ id: 5, text: 'It hinders economic progress', isSelected: false, isCorrect: true },		
		],
	},
	{
		id: 'quiz-42',
		type: 'quiz',
		roomId: 5,
		challengeNumber: 10,
		question: {
			text:'How are criminal cases allocated among judges in court?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'After negotiations with the court’s judges, the court president makes a decision on the allocation of the case', isSelected: false, isCorrect: true },
			{ id: 2, text: 'The judges themselves choose the cases that they think are the most interesting', isSelected: false, isCorrect: false },
			{ id: 3, text: 'The accused decides which judge shall treat their case', isSelected: false, isCorrect: false },
			{ id: 4, text: 'Each judge has one type of offence that they try', isSelected: false, isCorrect: false },	
		],
	},
	{
		id: 'quiz-43',
		type: 'quiz',
		roomId: 5,
		challengeNumber: 8,
		question: {
			text:'What does it mean that there is "openness" in the courts of Denmark?',
			showNumberOfAnswers: false,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'That everyone present gets to decide on the verdict', isSelected: false, isCorrect: false },
			{ id: 2, text: 'That there is live-streaming from all the courts in Denmark', isSelected: false, isCorrect: false },
			{ id: 3, text: 'That in principle all court sittings are public', isSelected: false, isCorrect: true },
			{ id: 4, text: 'That in principle all court sittings are closed to the public', isSelected: false, isCorrect: false },	
		],
	},
	{
		id: 'quiz-44',
		type: 'quiz',
		roomId: 5,
		challengeNumber: 11,
		question: {
			text:'What should I do if I come across corruption?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Keep it to myself', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Tell my closest manager', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Report it to the police', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Write an angry letter to the editor', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Check whether there is a whistleblower arrangement', isSelected: false, isCorrect: true },	
		],
	},
	{
		id: 'quiz-45',
		type: 'quiz',
		roomId: 5,
		challengeNumber: 7,
		question: {
			text:'What measures are in place to safeguard Denmark against corruption?',
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false,
		},
		answers: [
			{ id: 1, text: 'Raising the prices of cigarettes', isSelected: false, isCorrect: false },
			{ id: 2, text: 'Control of legal public administration', isSelected: false, isCorrect: true },
			{ id: 3, text: 'Using the free media to act as watchdogs', isSelected: false, isCorrect: true },
			{ id: 4, text: 'Extending humanitarian aid to crisis areas', isSelected: false, isCorrect: false },
			{ id: 5, text: 'Whistleblower arrangements', isSelected: false, isCorrect: true },	
		],
	},
];

export { quizzesData };
