/* eslint-disable indent */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import './star-rating.scss';
import { getIconIndex } from 'helpers/points-helper';

interface IProps {
	points: number;
	roomId: number;
}

const StarRating: React.FC<IProps> = ({ points, roomId }: IProps) => {
	const [starsNumber, setStarsNumber] = useState(0);
	const renderStars = (stars: number) => {
		const starComponents: any[] = [];

		for (let i = 0; i < 6; i++) {
			if (i < stars) {
				starComponents.push(
					<span key={i} className="Star Star--filled">
						<div className="inner"></div>
					</span>
				);
			} else {
				starComponents.push(
					<span key={i} className="Star Star">
						<div className="inner"></div>
					</span>
				);
			}
		}
		return starComponents;
	};
	useEffect(() => {
		setStarsNumber(getIconIndex(points, roomId));
	}, [points, roomId]);

	return <div className="Star-Rating">{renderStars(starsNumber)}</div>;
};

export default StarRating;
