import {roomsData} from 'data/rooms-data';
import {sortArrayByProperty} from './array-helper';
import { IPlayerData, IChallenge } from 'components/game/game-controller';

/**
 * Check if player has completed all rooms
 * @param {array} challengesData 
 * @param {object} playerData 
 */
export const areAllRoomsCompleted = (challengesData:IChallenge[], playerData:IPlayerData):boolean => {
	let allRoomsCompleted = false;

	let numberOfCompletedRooms = 0;
	roomsData.forEach((room) => {
		const roomChallengesData = challengesData.filter((challenge) => {return challenge.roomId === room.id;});
		const roomIsCompleted = isRoomCompleted(roomChallengesData, playerData);
		if (roomIsCompleted) numberOfCompletedRooms = numberOfCompletedRooms + 1;			
	});

	if (numberOfCompletedRooms === roomsData.length) allRoomsCompleted = true;

	return allRoomsCompleted;
};

/**
 * Check if player has completed room
 * @param {array} roomChallengesData 
 * @param {object} playerData 
 */
export const isRoomCompleted = (roomChallengesData:IChallenge[], playerData:IPlayerData):boolean => {
	let roomIsCompleted = false;

	if (roomChallengesData.length <= 0) {
		/* No challenges in room */
		roomIsCompleted = true;
	} else {
		/* Check last challenge of room */
		const roomChallengesDataSorted = sortArrayByProperty(roomChallengesData, 'challengeNumber', 'ASC');
		const lastChallengeId = roomChallengesDataSorted[roomChallengesData.length - 1].id;
		// const playerChallengeIndex = playerData.challenges.findIndex((challenge) => {
		// 	return challenge.id === lastChallengeId;
		// });
		// Refactor for IE
		let playerChallengeIndex = -1;
		// eslint-disable-next-line array-callback-return
		playerData.challenges.some((challenge, i: number) => {
			if (challenge.id === lastChallengeId) {
				playerChallengeIndex = i;
				return true;
			}
		});
		roomIsCompleted = 
			playerChallengeIndex >= 0 &&
			playerData.challenges[playerChallengeIndex].hasOwnProperty('completed') &&
			playerData.challenges[playerChallengeIndex].completed === true
		;
	}

	return roomIsCompleted;
};