import 'core-js/stable';
import 'regenerator-runtime/runtime';
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);