export interface IIcons {
	title: string;
	text?: string;
	pointsMin?: number;
	pointsMax?: number;
	id: string;
}

const icons = [
	{
		roomId: 1,
		points: [
			{ pointsMax: 0, id: 'none', title: '' },
			{ pointsMin: 1, pointsMax: 20, id: 'novice', title: 'Novice' },
			{ pointsMin: 21, pointsMax: 35, id: 'amateur', title: 'Amatør' },
			{ pointsMin: 36, pointsMax: 50, id: 'apprentice', title: 'Lærling' },
			{ pointsMin: 51, pointsMax: 64, id: 'expert', title: 'Kaptajn' },
			{ pointsMin: 65, pointsMax: 79, id: 'master', title: 'Mester' },
			{ pointsMin: 80, id: 'grand-master', title: 'Stormester' },
		],
	},
	{
		roomId: 2,
		points: [
			{ pointsMax: 0, id: 'none', title: '' },
			{ pointsMin: 1, pointsMax: 20, id: 'novice', title: 'Novice' },
			{ pointsMin: 21, pointsMax: 40, id: 'amateur', title: 'Amatør' },
			{ pointsMin: 41, pointsMax: 55, id: 'apprentice', title: 'Lærling' },
			{ pointsMin: 56, pointsMax: 70, id: 'expert', title: 'Kaptajn' },
			{ pointsMin: 71, pointsMax: 85, id: 'master', title: 'Mester' },
			{ pointsMin: 86, id: 'grand-master', title: 'Stormester' },
		],
	},
	{
		roomId: 3,
		points: [
			{ pointsMax: 0, id: 'none', title: '' },
			{ pointsMin: 1, pointsMax: 25, id: 'novice', title: 'Novice' },
			{ pointsMin: 26, pointsMax: 45, id: 'amateur', title: 'Amatør' },
			{ pointsMin: 46, pointsMax: 65, id: 'apprentice', title: 'Lærling' },
			{ pointsMin: 66, pointsMax: 85, id: 'expert', title: 'Kaptajn' },
			{ pointsMin: 86, pointsMax: 100, id: 'master', title: 'Mester' },
			{ pointsMin: 101, id: 'grand-master', title: 'Stormester' },
		],
	},
	{
		roomId: 4,
		points: [
			{ pointsMax: 0, id: 'none', title: '' },
			{ pointsMin: 1, pointsMax: 25, id: 'novice', title: 'Novice' },
			{ pointsMin: 26, pointsMax: 49, id: 'amateur', title: 'Rookie' },
			{ pointsMin: 50, pointsMax: 69, id: 'apprentice', title: 'Trainee' },
			{ pointsMin: 70, pointsMax: 84, id: 'expert', title: 'Captain' },
			{ pointsMin: 85, pointsMax: 98, id: 'master', title: 'Master' },
			{ pointsMin: 99, id: 'grand-master', title: 'Wizard' },
		],
	},
	
	{
		roomId: 5,
		points: [
			{ pointsMax: 0, id: 'none', title: '' },
			{ pointsMin: 1, pointsMax: 20, id: 'novice', title: 'Novice' },
			{ pointsMin: 21, pointsMax: 40, id: 'amateur', title: 'Rookie' },
			{ pointsMin: 41, pointsMax: 55, id: 'apprentice', title: 'Trainee' },
			{ pointsMin: 56, pointsMax: 70, id: 'expert', title: 'Captain' },
			{ pointsMin: 71, pointsMax: 85, id: 'master', title: 'Master' },
			{ pointsMin: 86, id: 'grand-master', title: 'Wizard' },
		],
	},
	{
		roomId: 6,
		points: [
			{ pointsMax: 0, id: 'none', title: '' },
			{ pointsMin: 1, pointsMax: 20, id: 'novice', title: 'Novice' },
			{ pointsMin: 21, pointsMax: 40, id: 'amateur', title: 'Amatør' },
			{ pointsMin: 41, pointsMax: 55, id: 'apprentice', title: 'Lærling' },
			{ pointsMin: 56, pointsMax: 85, id: 'expert', title: 'Kaptajn' },
			{ pointsMin: 71, pointsMax: 85, id: 'master', title: 'Mester' },
			{ pointsMin: 86, id: 'grand-master', title: 'Stormester' },
		],
	},
];

export { icons };
