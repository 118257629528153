// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import { renderMarkdown } from 'helpers/text-helper';
import './sort.scss';
import { ISortData } from 'data/sort-data';
import { setBackgroundColor, useWindowSize } from 'helpers/utils';
interface IProps {
	isHidden?: boolean;
	isPaused: boolean;
	selectedItemIndex?: number;
	animation?: string;
	sortData: ISortData;
	handleSelectItem: (itemIndex: number) => void;
	handleSelectBox: (boxId: string) => void;
}
const Sort: React.FC<IProps> = ({
	isHidden,
	isPaused,
	selectedItemIndex,
	animation,
	sortData,
	handleSelectItem,
	handleSelectBox,
}: IProps) => {
	const sizeCheck = useWindowSize();
	const [headerHeight, setHeaderHeight] = useState(80);
	useEffect(() => {
		const el = document.getElementById('SortHeader');
		const height = el ? el.clientHeight + 15 : 80;
		setHeaderHeight(height);
	}, [sizeCheck]);
	return (
		<div
			className={
				'Sort Sort--' +
				sortData.id +
				(isPaused ? ' Sort--paused' : '') +
				(isHidden ? ' Sort--hidden' : '')
			}
		>
			{/* Sort header */}
			<div
				id="SortHeader"
				className="Sort-header"
				style={{ backgroundColor: setBackgroundColor(sortData.roomId) }}
			>
				{/* Question */}
				<div className="Sort-question">
					{renderMarkdown(sortData.question.text)}
				</div>

				{/* Boxes */}
				<div
					id="SortBoxes"
					className={
						'Sort-boxes Sort-boxes--' + sortData.boxes.length + ' pointer'
					}
				>
					{sortData.boxes.map((box, index) => {
						let boxClass =
							'Sort-boxContainer Sort-boxContainer--' + (index + 1);
						if (box.id === 'true') {
							boxClass = boxClass + ' Sort-boxContainer--green';
						}
						if (box.id === 'false') {
							boxClass = boxClass + ' Sort-boxContainer--red';
						}
						return (
							<div
								key={index}
								className={boxClass}
								onClick={() => {
									if (!isPaused) handleSelectBox(box.id);
								}}
							>
								<div className="Sort-boxText">{renderMarkdown(box.name)}</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Sort body */}
			<div
				id="SortBody"
				className="Sort-body"
				style={{ paddingTop: `${headerHeight}px` }}
			>
				{/* Items */}
				<div id="SortItems" className="Sort-items">
					{sortData.items.map((item, index) => {
						let className = 'Sort-item pointer';
						if (sortData.completed) {
							className = className + ' placed';
						} else {
							if (index === selectedItemIndex) {
								if (animation) {
									className = className + ' ' + animation;
								} else {
									className = className + ' selected';
								}
							} else {
								if (item.isPlaced) className = className + ' placed';
							}
						}
						// const bgImg = null;
						// if (item.image) {
						//	bgImg = require('../../../assets/images/' + item.image);
						//	className = className + ' Sort-item--bgImg';
						// }
						return (
							<div
								key={index}
								className={className}
								// style={bgImg ? {backgroundImage: 'url(' + bgImg + ')'} : null}
								onClick={() => {
									if (!isPaused && item.isPlaced === false) {
										handleSelectItem(index);
									}
								}}
							>
								{/* bgImg && <img src={bgImg} alt={item.name} /> */}
								{renderMarkdown(item.name)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Sort;
