// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState, useEffect } from 'react';
import { roomsData } from 'data/rooms-data';
import { IPagesComponentProps } from 'components/game/game-controller';
import RoomOverview from './room-overview';

export interface IRoom {
	id: number;
	name: string;
	intro: string;
}
const RoomOverviewController: React.FC<IPagesComponentProps> = ({ goToPage, goToPreviousPage }: IPagesComponentProps) => {
	interface ROCState {
		userCanSelectRoom: boolean;
		rooms: IRoom[];
	}

	const [rOCState, setROCState] = useState<ROCState>({
		userCanSelectRoom: false,
		rooms: JSON.parse(JSON.stringify(roomsData)),
	});

	useEffect(() => {
		/* Activate user interaction */
		const timeout = setTimeout(() => {
			setROCState({ ...rOCState, userCanSelectRoom: true });
		}, 250);
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const props = {
		userCanSelectRoom: rOCState.userCanSelectRoom,
		goToPage: goToPage,
		goToPreviousPage: goToPreviousPage,
		rooms: rOCState.rooms,
	};

	return <RoomOverview {...props} />;
};

export default RoomOverviewController;
