// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import { challengeUiTexts } from 'data/ui-texts';
import { renderMarkdown } from 'helpers/text-helper';
import Popup from 'components/layout/popup';
import './quiz.scss';
import { IQuizAnswer } from 'data/quizzes-data';
import { setBackgroundColor, useWindowSize } from 'helpers/utils';
import { IGState, useGlobal } from 'components/game/game-controller';

interface IQuestion {
	text: string;
	showNumberOfAnswers: boolean;
	image: null;
	showImageInPopup: boolean;
	popupImage?: string;
}

interface IQuizData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IQuestion;
	answers: IQuizAnswer[];
	completed?: boolean;
}
interface IProps {
	isPaused: boolean;
	showImagePopup: boolean;
	quizData: IQuizData;
	toggleImagePopup: (bool: boolean) => void;
	handleSelectAnswer: (num: number) => void;
}

const Quiz: React.FC<IProps> = ({
	isPaused,
	showImagePopup,
	quizData,
	toggleImagePopup,
	handleSelectAnswer,
}: IProps) => {

	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [challengeUiTextsLang, setChallengeUiTextsLang] = React.useState({...challengeUiTexts.dk});
	React.useEffect(() => {
		setChallengeUiTextsLang(currentlang === 'en' ? challengeUiTexts.en : challengeUiTexts.dk );
	}, [currentlang]);
	
	const numberOfCorrectAnswers = quizData.answers.filter((answer) => {
		return answer.isCorrect === true;
	}).length;
	const imageThumb = null;
	// if (
	// 	quizData.question.image !== null &&
	// 	quizData.question.image.length > 0 &&
	// 	quizData.question.showImageInPopup
	// ) {
	// 	imageThumb = require('assets/images/challenges/' + quizData.question.image);
	// }
	const sizeCheck = useWindowSize();
	const [headerHeight, setHeaderHeight] = useState(80);
	useEffect(() => {
		const el = document.getElementById('QuizHeader');
		const height = el ? el.clientHeight + 15 : 80;
		setHeaderHeight(height);
	}, [sizeCheck]);

	return (
		<div
			className={
				'Quiz Quiz--' + quizData.id + (isPaused ? ' Quiz--paused' : '')
			}
		>
			{/* Quiz header */}
			<div
				id="QuizHeader"
				className="Quiz-header"
				style={{ backgroundColor: setBackgroundColor(quizData.roomId) }}
			>
				<div className="Quiz-headerContent">
					{/* Question & image thumbnail*/}
					<div
						className={
							'Quiz-question' + (imageThumb ? ' Quiz-question--left' : '')
						}
					>
						{renderMarkdown(quizData.question.text)}
						{/* Instructions */}
						{quizData.question.showNumberOfAnswers && (
							<div className="Quiz-instructions">
								{challengeUiTextsLang.choose} {numberOfCorrectAnswers}{' '}
								{challengeUiTextsLang.answers}
							</div>
						)}
					</div>

					{imageThumb && (
						<div
							className="Quiz-imageThumb"
							onClick={() => {
								toggleImagePopup(true);
							}}
						>
							<img src={imageThumb} alt={imageThumb} />
							<div className="Quiz-imageThumbZoom" />
						</div>
					)}
				</div>
			</div>

			{/* Quiz body */}
			<div
				id="QuizBody"
				className="Quiz-body"
				style={{ paddingTop: `${headerHeight}px` }}
			>
				{/* Image */}
				{quizData.question.image && !quizData.question.showImageInPopup && (
					<div className={'Quiz-image Quiz-image--' + quizData.id} />
				)}

				{/* Answers */}
				<div className="Quiz-answers">
					{quizData.answers.map((answer, index) => {
						let className = 'pointer Quiz-answer';
						if (answer.isSelected) {
							if (!quizData.completed) {
								className =
									className +
									' selected ' +
									(answer.isCorrect ? 'animateCorrect' : 'animateWrong');
							} else {
								className =
									className + ' ' + (answer.isCorrect ? 'correct' : 'wrong');
							}
						}
						return (
							<div
								key={index}
								className={className}
								onClick={() => {
									if (!isPaused && !answer.isSelected) {
										handleSelectAnswer(answer.id);
									}
								}}
							>
								{renderMarkdown(answer.text)}
							</div>
						);
					})}
				</div>
			</div>

			<Popup
				showPopup={showImagePopup}
				frameText={true}
				type="quizImage"
				title={null}
				text={null}
				image={quizData.question.popupImage}
				onClose={() => {
					toggleImagePopup(false);
				}}
			/>
		</div>
	);
};

export default Quiz;
