// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { generalUiTexts } from 'data/ui-texts';
import BackButton from 'components/layout/back-button';
import { IPagesComponentProps } from 'components/game/game-controller';
import ddlogo from 'assets/images/logo-dd.svg';
import { IGState, useGlobal } from 'components/game/game-controller';
import './about.scss';

const About: React.FC<IPagesComponentProps> = ({
	goToPreviousPage,
}: IPagesComponentProps) => {

	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [generalUiTextsLang, setGeneralUiTextsLang] = React.useState({...generalUiTexts.dk});
	React.useEffect(() => {
		setGeneralUiTextsLang(currentlang === 'en' ? generalUiTexts.en : generalUiTexts.dk );
	}, [currentlang]);

	return (
		<div className="About">
			<BackButton goToPreviousPage={goToPreviousPage} />
			<div className="About-content">
				<h1 className="About-heading">{generalUiTextsLang.aboutTitle}</h1>
				<p className="About-text">{generalUiTextsLang.developedBy}</p>
				<img src={ddlogo} alt="logo" className="About-ddLogo" />
			</div>

			<div className="About-footer">
				<div className="About-copyright">{generalUiTextsLang.copyright}</div>
			</div>
		</div>
	);
};

export default About;
