export interface IOrderQuestion {
	text: string;
}
export interface IOrderBox {
	id: string;
	name: number;
	placedItemId: string | null;
	correctItemId: string;
}

export interface IOrderItem {
	id: string;
	name: string;
}
export interface IOrderData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IOrderQuestion;
	boxes: IOrderBox[];
	items: IOrderItem[];
	completed?: boolean;
	errors?: number;
}

const orderData: IOrderData[] = [
	// {
	// 	id: 'order-1',
	// 	type: 'order',
	// 	roomId: 2,
	// 	challengeNumber: 9,
	// 	question: {
	// 		text: 'Sæt sagerne i rækkefølge efter hvor der blev afsuttet flest af ved byretterne i Danmark i 2019.',
	// 	},
	// 	boxes: [
	// 		{id: '1', name: 1, placedItemId: null, correctItemId: '1'},
	// 		{id: '2', name: 2, placedItemId: null, correctItemId: '2'},
	// 		{id: '3', name: 3, placedItemId: null, correctItemId: '3'},
	// 		{id: '4', name: 4, placedItemId: null, correctItemId: '4'},
	// 		{id: '5', name: 5, placedItemId: null, correctItemId: '5'},
	// 	],
	// 	items: [
	// 		{id: '1', name: 'Fogedsager'},
	// 		{id: '2', name: 'Straffesager'},
	// 		{id: '3', name: 'Notarialforret-ninger'},
	// 		{id: '4', name: 'Dødsboskif-tesager'},
	// 		{id: '5', name: 'Civile sager'},
	// 	]
	// },
	{
		id: 'order-2',
		type: 'order',
		roomId: 3,
		challengeNumber: 12,
		question: {
			text:
				'Hvert år vurderer Transparency International lande ift. korruption. Ranger landene fra mindst til mest korrupt (mindst korrupt først)',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Danmark' },
			{ id: '2', name: 'Letland' },
			{ id: '3', name: 'Thailand' },
			{ id: '4', name: 'Haiti' },
		],
	},
	{
		id: 'order-3',
		type: 'order',
		roomId: 3,
		challengeNumber: 13,
		question: {
			text:
				'Sæt landene i rækkefølge efter, hvor der i 2019 var flest kvindelige dommere i deres højesteret (flest kvinder først).',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Rumænien' },
			{ id: '2', name: 'Tyskland' },
			{ id: '3', name: 'Danmark' },
			{ id: '4', name: 'Malta' },
		],
	},
	// {
	// 	id: 'order-4',
	// 	type: 'order',
	// 	roomId: 3,
	// 	challengeNumber: 14,
	// 	question: {
	// 		text: 'Sæt landene i rækkefølge efter hvilket land, der havde flest dommere pr. 100.000 indbyggere i 2018 (flest dommere først).',
	// 	},
	// 	boxes: [
	// 		{id: '1', name: 1, placedItemId: null, correctItemId: '1'},
	// 		{id: '2', name: 2, placedItemId: null, correctItemId: '2'},
	// 		{id: '3', name: 3, placedItemId: null, correctItemId: '3'},
	// 		{id: '4', name: 4, placedItemId: null, correctItemId: '4'},

	// 	],
	// 	items: [
	// 		{id: '1', name: 'Slovenien'},
	// 		{id: '2', name: 'Grækenland'},
	// 		{id: '3', name: 'Spanien'},
	// 		{id: '4', name: 'Danmark'},
	// 	]
	// },
	{
		id: 'order-5',
		type: 'order',
		roomId: 3,
		challengeNumber: 16,
		question: {
			text:
				'Sæt landene i rækkefølge efter, hvor stor tillid befolkningen har til domstolenes uafhængighed (størst tillid først).',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Danmark' },
			{ id: '2', name: 'Belgien' },
			{ id: '3', name: 'Malta' },
			{ id: '4', name: 'Polen' },
		],
	},
	{
		id: 'order-6',
		type: 'order',
		roomId: 3,
		challengeNumber: 17,
		question: {
			text: 'Hvilket land bruger færrest penge på deres retsvæsen i forhold til landets BNP?',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Danmark' },
			{ id: '2', name: 'Finland' },
			{ id: '3', name: 'Grækenland' },
			{ id: '4', name: 'Ungarn' },
		],
	},
	{
		id: 'order-7',
		type: 'order',
		roomId: 4,
		challengeNumber: 7,
		question: {
			text: 'Place the countries in order according to where in 2019 there were most female judges in their Supreme Court',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Romania' },
			{ id: '2', name: 'Germany' },
			{ id: '3', name: 'Denmark' },
			{ id: '4', name: 'Czech Republic' },
		],
	},
	{
		id: 'order-8',
		type: 'order',
		roomId: 4,
		challengeNumber: 8,
		question: {
			text: 'In what country do citizens have the highest level of trust in the independence of their judiciary (according to Eurobarometers survey from 2019)?',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Denmark' },
			{ id: '2', name: 'Belgium' },
			{ id: '3', name: 'Malta' },
			{ id: '4', name: 'Poland' },
		],
	},
	{
		id: 'order-9',
		type: 'order',
		roomId: 4,
		challengeNumber: 9,
		question: {
			text: 'Every year Transparency International evaluates the corruption level of countries. Rank the countries starting with the least corrupt first',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Denmark' },
			{ id: '2', name: 'Latvia' },
			{ id: '3', name: 'Thailand' },
			{ id: '4', name: 'Haiti' },
		],
	},
	{
		id: 'order-10',
		type: 'order',
		roomId: 6,
		challengeNumber: 4,
		question: {
			text: 'Hvert år vurderer Transparency International lande iht. korruption. Ranger landene fra mindst til mest korrupt (mindst korrupt først).',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Singapore' },
			{ id: '2', name: 'Norge' },
			{ id: '3', name: 'Portugal' },
			{ id: '4', name: 'Italien' },
		],
	},
	{
		id: 'order-11',
		type: 'order',
		roomId: 5,
		challengeNumber: 4,
		question: {
			text: 'Every year Transparency International evaluates countries with regard to corruption. Rank the following countries on a scale from lowest to highest level (lowest first):',
		},
		boxes: [
			{ id: '1', name: 1, placedItemId: null, correctItemId: '1' },
			{ id: '2', name: 2, placedItemId: null, correctItemId: '2' },
			{ id: '3', name: 3, placedItemId: null, correctItemId: '3' },
			{ id: '4', name: 4, placedItemId: null, correctItemId: '4' },
		],
		items: [
			{ id: '1', name: 'Singapore' },
			{ id: '2', name: 'Norway' },
			{ id: '3', name: 'Portugal' },
			{ id: '4', name: 'Italy' },
		],
	},
];

export { orderData };
