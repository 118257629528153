// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { ReactComponent as BackButtonSVG } from 'assets/images/icon-back.svg';
import './back-button.scss';

interface IProps {
	roomId?: number;
	type?: string | null;
	goToPreviousPage: () => void;
}
const BackButton: React.FC<IProps> = ({
	type = null,
	goToPreviousPage,
}: IProps) => {
	const className = `BackButton BackButton--${type} pointer`;
	return (
		<BackButtonSVG
			className={className}
			onClick={() => {
				goToPreviousPage();
			}}
		/>
	);
};

export default BackButton;
