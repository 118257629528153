// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import marked from 'marked';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
const renderMarkdown = (markdown: any): any => {
	const lexed = marked.lexer(markdown);
	const parsed = marked.parser(lexed);
	return <div dangerouslySetInnerHTML={{ __html: parsed }} />;
};

export { renderMarkdown };
