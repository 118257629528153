// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState, useEffect } from 'react';
import { roomsData } from 'data/rooms-data';
import { quizzesData } from 'data/quizzes-data';
import { orderData } from 'data/order-data';
import { sortData } from 'data/sort-data';
import { pairsData } from 'data/pairs-data';
import { sortArrayByProperty } from 'helpers/array-helper';
import { IPagesComponentProps } from 'components/game/game-controller';
import { IRoom } from 'components/room-overview/room-overview-controller';
import { IChallenge } from 'components/game/game-controller';
import Room from './room';

const RoomController: React.FC<IPagesComponentProps> = ({
	roomId,
	playerData,
	goToPage,
	goToPreviousPage,
	updatePlayerData,
	updateStats,
}: IPagesComponentProps) => {
	interface IState {
		isLoading: boolean;
		roomStreakUnlocked: boolean;
		showRoomCompletedPopup: boolean;
		roomData: IRoom | null;
		challengesData: any[];
		roomChallengesData: any[];
		allRoomsChallengesData: { room1: any[]; room2: any[]; room3: any[]; room4: any[]; room5: any[]; room6: any[] };
	}
	const [roomControllerState, setRoomControllerState] = useState<IState>({
		isLoading: true,
		roomStreakUnlocked: false,
		showRoomCompletedPopup: false,
		roomData: null,
		challengesData: [],
		roomChallengesData: [],
		allRoomsChallengesData: { room1: [], room2: [], room3: [], room4: [], room5: [], room6: [] },
	});

	useEffect(() => {
		// Component mounted
		/* Get room data */
		let roomData = null;
		if (
			roomsData.some((room) => {
				return room.id === roomId;
			})
		) {
			roomData = roomsData.filter((room) => {
				return room.id === roomId;
			})[0];
		}

		/* Get challenges of room */
		const challengesData = [...quizzesData, ...orderData, ...sortData, ...pairsData];

		const filterAndSort = (id: number) => {
			let roomChallenges = challengesData.filter((challengeData) => {
				return (
					challengeData.roomId === id && 
					challengeData.challengeNumber && 
					challengeData.challengeNumber >= 0
				);
			});
			roomChallenges = sortArrayByProperty(roomChallenges, 'challengeNumber', 'ASC');
			return roomChallenges;
		};

		const roomChallengesData = filterAndSort(roomId);

		const allRoomsChallengesData: {
			room1: any[];
			room2: any[];
			room3: any[];
			room4: any[];
			room5: any[];
			room6: any[];
		} = {
			room1: filterAndSort(1),
			room2: filterAndSort(2),
			room3: filterAndSort(3),
			room4: filterAndSort(4),
			room5: filterAndSort(5),
			room6: filterAndSort(6),
		};

		/* Update state */
		setRoomControllerState({
			...roomControllerState,
			roomData,
			challengesData,
			roomChallengesData,
			allRoomsChallengesData,
			isLoading: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Complete room
	 * @param {bool} roomStreakUnlocked
	 */
	const handleRoomComplete = (roomStreakUnlocked: boolean) => {
		updateStats('completed', roomId);
		setRoomControllerState({
			...roomControllerState,
			showRoomCompletedPopup: true,
			roomStreakUnlocked: roomStreakUnlocked,
		});
		window.scrollTo(0, 0);
	};

	/**
	 * Reset room
	 */
	const handleResetRoom = () => {
		const currentPlayerData = JSON.parse(JSON.stringify(playerData));
		const challengesData = [...quizzesData, ...orderData, ...sortData, ...pairsData];
		const resetChallenges = currentPlayerData.challenges.filter((challenge: IChallenge) => {
			const challengeData = challengesData.find((ch) => {
				return ch.id === challenge.id;
			});
			if (challengeData) {
				return challengeData.roomId !== roomId;
			}
			return false;
		});
		currentPlayerData.streaks[`room${roomId.toString()}`] = 0;
		currentPlayerData.points[`room${roomId.toString()}`] = 0;
		updatePlayerData({
			...playerData,
			challenges: resetChallenges,
			streaks: currentPlayerData.streaks,
			points: currentPlayerData.points,
		}).then(() => {
			// console.log(playerData); TODO! Why does uncommenting things change the playerData state?!
			setRoomControllerState({
				...roomControllerState,
				showRoomCompletedPopup: false,
				roomStreakUnlocked: false,
			});
		});
	};

	/**
	 * Render component
	 */
	return (
		<Room
			roomStreakUnlocked={roomControllerState.roomStreakUnlocked}
			showRoomCompletedPopup={roomControllerState.showRoomCompletedPopup}
			roomId={roomId}
			roomData={roomControllerState.roomData}
			roomChallengesData={roomControllerState.roomChallengesData}
			allRoomsChallengesData={roomControllerState.allRoomsChallengesData}
			playerData={playerData}
			goToPage={goToPage}
			goToPreviousPage={goToPreviousPage}
			updatePlayerData={updatePlayerData}
			handleRoomComplete={handleRoomComplete}
			handleResetRoom={handleResetRoom}
			updateStats={updateStats}
			challengesData={roomControllerState.challengesData}
		/>
	);
};

export default RoomController;
