// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { generalUiTexts } from 'data/ui-texts';
import { IRoom } from './room-overview-controller';
import { IGState, IGActions, useGlobal } from 'components/game/game-controller';
import { ReactComponent as DDlogo } from 'assets/images/logo-dd.svg';
import './room-overview.scss';

interface IProps {
	userCanSelectRoom: boolean;
	goToPage: (pageId: string | null, roomId?: number | null | undefined) => void;
	goToPreviousPage: () => void;
	rooms: IRoom[];
}

const RoomOverview: React.FC<IProps> = ({ userCanSelectRoom, goToPage, goToPreviousPage, rooms }: IProps) => {
	const [currentlang, toggleLang] = useGlobal<string, (currentLang: string) => void>(
		(state: IGState) => {
			return state.currentlang;
		},
	(actions: IGActions) => {
		return actions.toggleLang;
	}
	);

	const [generalUiTextsLang, setGeneralUiTextsLang] = React.useState({...generalUiTexts.dk});
	React.useEffect(() => {
		setGeneralUiTextsLang(currentlang === 'en' ? generalUiTexts.en : generalUiTexts.dk );
	}, [currentlang]);

	return (
		<div id="RoomOverview" className="RoomOverview">
			{/* Header */}
			<div className="RoomOverview-header">
				<DDlogo
					className="RoomOverview-ddLogo"
					onClick={() => {
						goToPreviousPage();
					}}
				/>
				<div
					className={`pointer RoomOverview-chooseLanguage lang--${currentlang === 'en' ? 'en' : 'dk'}`}
					onClick={() => {
						toggleLang(currentlang === 'en' ? 'en' : 'dk');
					}}
				/>
			</div>

			{/* Intro text */}
			<p className="RoomOverview-description">{generalUiTextsLang.introText}</p>

			{/* Rooms */}
			<div className="RoomOverview-rooms">
				{rooms.map((room, i) => {
					if (currentlang === 'dk' && (i < 3 || i > 4)) {
						return (
							<div
								key={i}
								className={`RoomOverview-Btn RoomOverview-Btn-${i} pointer`}
								onClick={() => {
									if (userCanSelectRoom) {
										goToPage('room', room.id);
									}
								}}
							>
								<p>
									<span>{room.name}</span>
									{room.intro}
								</p>
							</div>
						);
					} else if (currentlang === 'en' && (i === 3 || i === 4)) {
						return (
							<div
								key={i}
								className={`RoomOverview-Btn RoomOverview-Btn-${i} pointer`}
								onClick={() => {
									if (userCanSelectRoom) {
										goToPage('room', room.id);
									}
								}}
							>
								<p>
									<span>{room.name}</span>
									{room.intro}
								</p>
							</div>
						);
					}
					return null;
				})}
			</div>			
		
			{/* Link to about page */}
			<p
				className="RoomOverview-info pointer"
				onClick={() => {
					if (userCanSelectRoom) {
						goToPage('about');
					}
				}}
			>
				{generalUiTextsLang.moreInfo}
			</p>
		</div>
	);
};

export default RoomOverview;
