// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react';
import useStateCallback from 'helpers/use-state-callback';
import appConfig from 'config/app.config';
import { sortArrayByProperty, shuffleArray } from 'helpers/array-helper';
import { sortPoints, streakPoints } from 'data/points-data';
import { getIconType } from 'helpers/points-helper';
import Sort from './sort';
import { IResolveStatus } from '../challenge-controller';
import { IChallenge, IPlayerData } from 'components/game/game-controller';
import { ISortItem } from 'data/sort-data';

interface IProps {
	roomId: number;
	challengeData: IChallenge;
	playerData: IPlayerData;
	toggleStreakPopup: (
		showStreakPopup: boolean,
		challengeCompleted?: boolean | undefined,
		isLastChallengeInRoom?: boolean | undefined,
		newPointsTierUnlocked?: boolean | undefined
	) => void;
	completeChallenge: (
		challengeId: string,
		points: number,
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		playerChallenges: any, 
		playerStreaks: any,
		playerpoints: any
	) => Promise<null>;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
	isHidden?: boolean;
}

const SortController: React.FC<IProps> = ({
	isHidden,
	roomId,
	challengeData,
	playerData,
	toggleStreakPopup,
	updatePlayerData,
	completeChallenge,
}: IProps) => {
	const [sortControllerState, setSortControllerState] = useStateCallback({
		isLoading: true,
		isPaused: false,
		animation: null,
		sortData: null,
		selectedItemIndex: null,
	});

	/**
	 * Load sort & sync with player
	 */
	const loadChallenge = () => {
		/* Get sort data */
		const parsedChallengeData = JSON.parse(JSON.stringify(challengeData));
		parsedChallengeData.completed = false;
		parsedChallengeData.errors = 0;

		/* Sort / shuffle items */
		if (
			parsedChallengeData.hasOwnProperty('sortItems') &&
			parsedChallengeData.sortItems === true
		) {
			parsedChallengeData.items = sortArrayByProperty(
				parsedChallengeData.items,
				'id',
				'ASC'
			);
		} else {
			parsedChallengeData.items = shuffleArray(parsedChallengeData.items);
		}

		/* Sync with player progress */
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.some((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})
		) {
			const playerChallengeData = playerData.challenges.filter((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				parsedChallengeData.completed = playerChallengeData.completed;
			}
			parsedChallengeData.errors = playerChallengeData.errors;
			parsedChallengeData.items.forEach((item: ISortItem) => {
				if (playerChallengeData.sortedItems.indexOf(item.id) >= 0) {
					item.isPlaced = true;
				}
			});
		}

		/* Update state */
		setSortControllerState(
			{
				...sortControllerState,
				isLoading: false,
				isPaused: parsedChallengeData.completed,
				sortData: parsedChallengeData,
			},
			() => {
				/* Adjust body height */
				const headerElement = document.getElementById('SortHeader');
				const bodyElement = document.getElementById('SortBody');
				if (headerElement && bodyElement) {
					const fontSize = 16 * (headerElement.clientWidth / 360);
					const biggerFontsize = 2.5 * fontSize;
					const headerHeight = headerElement.clientHeight + biggerFontsize;
					bodyElement.style.paddingTop = headerHeight + 'px';
				}
			}
		);
	};

	/**
	 * Select item
	 * @param {number} itemIndex
	 */
	const handleSelectItem = (itemIndex: number) => {
		if (sortControllerState.isPaused) return;

		setSortControllerState({ ...sortControllerState, isPaused: true }, () => {
			const selectedItemIndex =
				sortControllerState.selectedItemIndex === itemIndex ? null : itemIndex;
			setSortControllerState({
				...sortControllerState,
				isPaused: false,
				selectedItemIndex,
			});
		});
	};

	/**
	 * Select box
	 * @param {string} boxId
	 */
	const handleSelectBox = (boxId: string) => {
		if (
			sortControllerState.isPaused ||
			sortControllerState.selectedItemIndex === null
		) {
			return;
		}

		setSortControllerState({ ...sortControllerState, isPaused: true }, () => {
			const parsedChallengeData = JSON.parse(
				JSON.stringify(sortControllerState.sortData)
			);
			const isCorrect =
				parsedChallengeData.items[sortControllerState.selectedItemIndex]
					.correctBoxId === boxId;

			/* Update game data */
			if (!isCorrect) {
				/* Wrong placement */
				parsedChallengeData.errors = parsedChallengeData.errors + 1;
				setSortControllerState({
					...sortControllerState,
					animation: 'wrong',
					sortData: parsedChallengeData,
				});
				/* Correct placement */
			} else {
				parsedChallengeData.items[
					sortControllerState.selectedItemIndex
				].isPlaced = true;
				setSortControllerState({
					...sortControllerState,
					animation: 'correct',
					sortData: parsedChallengeData,
				});
			}

			/* Player data - progress and errors */
			let playerChallenges: any[] = [];
			const sortedItems: string[] = [];
			parsedChallengeData.items.forEach((item: ISortItem) => {
				if (item.isPlaced) sortedItems.push(item.id);
			});
			if (playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(playerData.challenges));
			}
			// const playerChallengeIndex = playerChallenges.findIndex(
			// 	(challenge: IChallenge) => {
			// 		return challenge.id === parsedChallengeData.id;
			// 	}
			// );
			// Refactor for IE
			let playerChallengeIndex = -1;
			// eslint-disable-next-line array-callback-return
			playerChallenges.some((challenge: IChallenge, i: number) => {
				if (challenge.id === parsedChallengeData.id) {
					playerChallengeIndex = i;
					return true;
				}
			});
			if (playerChallengeIndex === -1) {
				/* First action, add challenge to playerChallenges */
				playerChallenges.push({
					id: parsedChallengeData.id,
					completed: false,
					errors: parsedChallengeData.errors,
					sortedItems: sortedItems,
				});
			} else {
				/* Update challenge in playerChallenges */
				playerChallenges[playerChallengeIndex].errors =
					parsedChallengeData.errors;
				playerChallenges[playerChallengeIndex].sortedItems = sortedItems;
			}

			/* Player data - points */
			const playerPoints = JSON.parse(JSON.stringify(playerData.points));

			/* Player data - streaks */
			let streakUnlocked = false;
			let newPointsTierUnlocked = false;
			let playerStreaks: any = {};
			if (appConfig.useStreaks) {
				if (playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(playerData.streaks));
				}

				if (!playerStreaks.hasOwnProperty(`room${roomId.toString()}`)) {
					playerStreaks[`room${roomId.toString()}`] = 0;
				}
				if (
					playerStreaks[`room${roomId.toString()}`] !== -1 ||
					!appConfig.limitStreaksPerRoom
				) {
					if (isCorrect) {
						playerStreaks[`room${roomId.toString()}`] =
							playerStreaks[`room${roomId.toString()}`] + 1;
					} else {
						playerStreaks[`room${roomId.toString()}`] = 0;
					}
					const roomStreak = parseInt(
						playerStreaks[`room${roomId.toString()}`]
					);
					if (roomStreak === streakPoints.streakMarker) {
						streakUnlocked = true;
						const prevPlayerPoints = playerPoints[`room${roomId.toString()}`];
						playerPoints[`room${roomId.toString()}`] =
							playerPoints[`room${roomId.toString()}`] +
							streakPoints.streakPoints;
						newPointsTierUnlocked =
							getIconType(prevPlayerPoints, roomId) !== 
							getIconType(playerPoints[`room${roomId.toString()}`], roomId);
						if (appConfig.limitStreaksPerRoom) {
							playerStreaks[`room${roomId.toString()}`] = -1;
						} else {
							playerStreaks[`room${roomId.toString()}`] = 0;
						}
					}
				}
			}

			/* Update player data */
			updatePlayerData({
				...playerData,
				challenges: playerChallenges,
				streaks: playerStreaks,
				points: playerPoints,
			}).then((response) => {
				if (response.status === 'ok') {
					setTimeout(() => {
						/* Check if all items have been sorted */
						const challengeIsComplete = !parsedChallengeData.items.some(
							(item: ISortItem) => {
								return item.isPlaced === false;
							}
						);

						if (challengeIsComplete) {
							/* Update game state, handle challenge completed */
							setSortControllerState({
								...sortControllerState,
								selectedItemIndex: null,
								animation: null,
								sortData: parsedChallengeData,
							});
							sortingCompleted(
								streakUnlocked,
								newPointsTierUnlocked,
								parsedChallengeData,
								playerChallenges,
								playerStreaks,
								playerPoints
							);
						} else {
							/* Update game state, show streak popup */
							setSortControllerState({
								...sortControllerState,
								isPaused: false,
								selectedItemIndex: null,
								animation: null,
								sortData: parsedChallengeData,
							});
							if (streakUnlocked) {
								toggleStreakPopup(true, false, false, newPointsTierUnlocked);
							}
						}
					}, 500);
				} else {
					console.log('error');
				}
			});
		});
	};

	/**
	 * Sorting completed
	 * @param {bool} streakUnlocked
	 */
	const sortingCompleted = (
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		sortData: any,
		playerChallenges: any,
		playerStreaks: any,
		playerPoints: any
	) => {
		const parsedChallengeData = { ...sortData };
		parsedChallengeData.completed = true;

		let points = sortPoints.minPoints;
		// const pointIndex = sortPoints.pointLimits.findIndex((limit) => {
		// 	return parsedChallengeData.errors <= limit;
		// });
		// Refactor for IE
		let pointIndex = -1;
		// eslint-disable-next-line array-callback-return
		sortPoints.pointLimits.some((limit, i: number) => {
			if (parsedChallengeData.errors <= limit) {
				pointIndex = i;
				return true;
			}
		});
		if (pointIndex >= 0) points = sortPoints.pointValues[pointIndex];

		completeChallenge(
			parsedChallengeData.id,
			points,
			streakUnlocked,
			newPointsTierUnlocked,
			playerChallenges,
			playerStreaks,
			playerPoints
		).then(() => {
			setSortControllerState({
				...sortControllerState,
				sortData: parsedChallengeData,
			});
		});
	};

	useEffect(() => {
		// Component mounted
		// Load challenge
		loadChallenge();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSortControllerState(
			{ ...sortControllerState, isLoading: true, sortData: null },
			() => {
				loadChallenge();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeData.id]);
	if (!sortControllerState.isLoading && sortControllerState.sortData) {
		return (
			<Sort
				isHidden={isHidden}
				isPaused={sortControllerState.isPaused}
				selectedItemIndex={sortControllerState.selectedItemIndex}
				animation={sortControllerState.animation}
				sortData={sortControllerState.sortData}
				handleSelectItem={handleSelectItem}
				handleSelectBox={handleSelectBox}
			/>
		);
	}
	return null;
};

export default SortController;
