// eslint-disable-next-line @typescript-eslint/no-use-before-define
import { useEffect, useState } from 'react';

/* eslint-disable indent */
const setBackgroundColor = (roomId: number): string => {
	let backgroundColor = '';
	switch (roomId) {
		case 1:
			backgroundColor = '#AABEDC';
			break;
		case 2:
			backgroundColor = '#475674';
			break;
		case 3:
			backgroundColor = '#324632';
			break;
		case 4:
			backgroundColor = '#FD8264';
			break;
		case 5:
			backgroundColor = '#6B1B21';
			break;
		case 6:
			backgroundColor = '#0A1E46';
			break;
		default:
			backgroundColor = '#ccc';
			break;
	}
	return backgroundColor;
};

interface IState {
	width?: number;
	height?: number;
}
// Hook
const useWindowSize = (): IState => {
	// Initialize state with undefined width/height so server and client renders match

	const [windowSize, setWindowSize] = useState<IState>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;
};

export { setBackgroundColor, useWindowSize };
