// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react';
import useStateCallback from 'helpers/use-state-callback';
import { getIconType } from 'helpers/points-helper';
import Challenge from './challenge';
import { IPlayerData, IChallenge } from 'components/game/game-controller';
export interface IResolveStatus {
	status: string;
}
interface IProps {
	roomId: number;
	roomChallengesData: IChallenge[];
	playerData: IPlayerData;
	goToPage: (pageId: string | null, roomId?: number | null) => void;
	goToPreviousPage: () => void;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
	handleRoomComplete: (roomStreakUnlocked: boolean) => void;
	updateStats: (type: string, roomId: number) => void;
}

const ChallengeController: React.FC<IProps> = ({
	roomId,
	roomChallengesData,
	playerData,
	goToPage,
	goToPreviousPage,
	updatePlayerData,
	handleRoomComplete,
	updateStats
}: IProps) => {
	let timeoutfunc: ReturnType<typeof setTimeout> | null = null;

	const [cCState, setCCState] = useStateCallback({
		isLoading: true,
		challengeCompleted: false,
		streakUnlocked: false,
		showChallengeCompletedPopup: false,
		showNewPointsTierPopup: false,
		showStreakPopup: false,
		challengeIndex: 0,
		challengesData: [],
	});

	useEffect(() => {
		/**
		 * Component mounted
		 */
		/* Get index of first un-completed challenge */
		let challengeIndex = 0;
		let allChallengesCompleted = false;
		let endLoop = false;
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.length > 0
		) {
			roomChallengesData.forEach((challengeData, index) => {
				if (endLoop) return;
				// const playerChallengeIndex = playerData.challenges.findIndex(
				// 	(challenge) => {
				// 		return challenge.id === challengeData.id;
				// 	}
				// );
				// Refactor for IE
				let playerChallengeIndex = -1;
				// eslint-disable-next-line array-callback-return
				playerData.challenges.some((challenge, i) => {
					if (challenge.id === challengeData.id) {
						playerChallengeIndex = i;
						return true;
					}
				});
				/* First un-competed challenge */
				if (
					playerChallengeIndex < 0 ||
					!playerData.challenges[playerChallengeIndex].completed
				) {
					challengeIndex = index;
					endLoop = true;
				}

				/* End of loop, all challenges are completed */
				if (index === roomChallengesData.length - 1) {
					challengeIndex = roomChallengesData.length - 1;
					allChallengesCompleted = true;
				}
			});
		}

		/* Update state, finish loading */
		setCCState(
			{ ...cCState, isLoading: false, allChallengesCompleted, challengeIndex },
			() => {
				/* All challenges completed */
				if (allChallengesCompleted) handleRoomComplete(false);
			}
		);

		return () => {
			/**
			 * Component will unmount equiv
			 */

			if (timeoutfunc) clearTimeout(timeoutfunc);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Navigate to specific challenge in current room
	 * @param {number} challengeIndex
	 */
	const goToChallenge = (challengeIndex: number) => {
		setCCState({
			...cCState,
			challengeIndex: challengeIndex,
			challengeCompleted: false,
			showChallengeCompletedPopup: false,
			showNewPointsTierPopup: false,
			showStreakPopup: false,
		});
		window.scrollTo(0, 0);
	};

	/**
	 * Navigate to the next challenge in current room
	 */
	const goToNextChallenge = () => {
		if (cCState.challengeIndex < roomChallengesData.length - 1) {
			goToChallenge(cCState.challengeIndex + 1);
		}
	};

	/**
	 * Show / hide streak popup
	 * @param {bool} showStreakPopup
	 */
	const toggleStreakPopup = (
		showStreakPopup: boolean,
		challengeCompleted = false,
		isLastChallengeInRoom = false,
		newPointsTierUnlocked = false
	) => {
		setCCState(
			{
				...cCState,
				showStreakPopup: showStreakPopup,
				showNewPointsTierPopup: newPointsTierUnlocked,
			},
			() => {
				if (challengeCompleted && isLastChallengeInRoom) {
					handleRoomComplete(true);
				} else if (!showStreakPopup && challengeCompleted) {
					goToNextChallenge();
				}
			}
		);
	};

	const closeNewPointsTierPopup = (isLastChallengeInRoom = false) => {
		setCCState(
			{
				...cCState,
				showNewPointsTierPopup: false,
			},
			() => {
				if (isLastChallengeInRoom) {
					handleRoomComplete(false);
				} else {
					goToNextChallenge();
				}
			}
		);
	};
	/**
	 * Complete challenge
	 * @param {string} challengeId
	 * @param {number} points
	 * @param {bool} streakUnlocked
	 */
	const completeChallenge = (
		challengeId: string,
		points: number,
		streakUnlocked = false,
		newPointsTierUnlocked = false,
		playerChallenges: any,
		playerStreak: any,
		playerPoints: any
	) => {
		const challengeCompleted = true;

		/* If first challenge in room, log room started */
		if (cCState.challengeIndex === 0) {
			updateStats('started', roomId);
		}

		/* Check if last challenge in room */
		const isLastChallengeInRoom =
			cCState.challengeIndex >= roomChallengesData.length - 1;

		/* Update challenge data */
		// const playerChallenges = JSON.parse(JSON.stringify(playerData.challenges));
		// const playerChallengeIndex = playerChallenges.findIndex(
		// 	(challenge: IChallenge) => {
		// 		return challenge.id === challengeId;
		// 	}
		// );
		// Refactor for IE
		let playerChallengeIndex = -1;
		// eslint-disable-next-line array-callback-return
		playerChallenges.some((challenge: IChallenge, i: number) => {
			if (challenge.id === challengeId) {
				playerChallengeIndex = i;
				return true;
			}
		});
		playerChallenges[playerChallengeIndex] = {
			...playerChallenges[playerChallengeIndex],
			completed: true,
			points: points,
		};

		// /* Update total points */
		// const totalPoints = playerData.hasOwnProperty('points')
		// 	? playerData.points
		// 	: 0;
		// const newTotalPoints = totalPoints + points;
		// const totalPoints = JSON.parse(JSON.stringify(playerData.points));
		const totalPoints = playerPoints;

		const initialRoomPoints = totalPoints[`room${roomId.toString()}`];
		const newRoomPoints = initialRoomPoints + points;
		totalPoints[`room${roomId.toString()}`] = newRoomPoints;
		/* Check if player reached a new points tier */
		const showNewPointsTierPopup =
			newPointsTierUnlocked ||
			getIconType(initialRoomPoints, roomId) !== getIconType(newRoomPoints, roomId);

		/* Show streak popup / challenge completed / room completed popup */
		const showStreakPopup = streakUnlocked;
		const showChallengeCompletedPopup = !isLastChallengeInRoom;
		timeoutfunc = setTimeout(() => {
			setCCState({
				...cCState,
				challengeCompleted,
				showChallengeCompletedPopup,
				showStreakPopup,
				showNewPointsTierPopup,
				streakUnlocked,
			});
		}, 500);

		if (isLastChallengeInRoom && !showStreakPopup && !showNewPointsTierPopup) {
			/* Show room completed popup */
			timeoutfunc = setTimeout(() => {
				handleRoomComplete(true);
			}, 500);
		}

		/* Update player data  */
		return updatePlayerData({
			...playerData,
			challenges: playerChallenges,
			points: totalPoints,
			streaks: playerStreak,
		}).then(() => {
			return null;
		});
	};

	if (cCState.isLoading) return null;

	/* Get status of each challenge (ready / completed) */
	const parsedRoomChallengesData = JSON.parse(
		JSON.stringify(roomChallengesData)
	);

	parsedRoomChallengesData.forEach((challengeData: IChallenge, i: number) => {
		let status = 'ready';
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.some((challenge) => {
				return challenge.id === challengeData.id;
			})
		) {
			const playerChallengeData = playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.completed) {
				status = 'completed';
			}
		}
		parsedRoomChallengesData[i].status = status;
	});

	const isLastChallengeInRoom =
		cCState.challengeIndex >= parsedRoomChallengesData.length - 1;

	return (
		<Challenge
			challengeCompleted={cCState.challengeCompleted}
			isLastChallengeInRoom={isLastChallengeInRoom}
			showChallengeCompletedPopup={cCState.showChallengeCompletedPopup}
			showNewPointsTierPopup={cCState.showNewPointsTierPopup}
			showStreakPopup={cCState.showStreakPopup}
			roomId={roomId}
			challengeIndex={cCState.challengeIndex}
			roomChallengesData={parsedRoomChallengesData}
			playerData={playerData}
			goToPage={goToPage}
			goToPreviousPage={goToPreviousPage}
			goToChallenge={goToChallenge}
			goToNextChallenge={!isLastChallengeInRoom ? goToNextChallenge : null}
			toggleStreakPopup={toggleStreakPopup}
			closeNewPointsTierPopup={closeNewPointsTierPopup}
			completeChallenge={completeChallenge}
			handleRoomComplete={handleRoomComplete}
			updatePlayerData={updatePlayerData}
		/>
	);
};

export default ChallengeController;
