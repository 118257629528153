import { useState, useEffect, useRef } from 'react';

const useStateCallback = (initialState: any): any => {
	const [state, setState] = useState(initialState);
	const cbRef = useRef<any>(null);

	const setStateCallback = (state: any, cb: any) => {
		cbRef.current = cb;
		setState(state);
	};

	useEffect(() => {
		if (cbRef.current) {
			cbRef.current(state);
			cbRef.current = null;
		}
	}, [state]);

	return [state, setStateCallback];
};

export default useStateCallback;
