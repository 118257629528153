/* TEMPLATE
{
	id: '',
	type: 'sort',
	roomId: 1,
	challengeNumber: 1,
	question: {
		text: ''
	},
	boxes: [
		{id: '', name: ''},
		{id: '', name: ''},
	],
	items: [
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''}
	]
},
*/
export interface ISortQuestion {
	text: string;
}
export interface ISortBox {
	id: string;
	name: string;
}
export interface ISortItem {
	id: string;
	name: string;
	isPlaced: boolean;
	correctBoxId: string;
}
export interface ISortData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: ISortQuestion;
	boxes: ISortBox[];
	items: ISortItem[];
	completed?: boolean;
	errors?: number;
}

const sortData: ISortData[] = [
	{
		id: 'sort-1',
		type: 'sort',
		roomId: 1,
		challengeNumber: 1,
		question: {
			text:
				'I Danmark opdeler vi magten i den lovgivende, den udøvende og den dømmende magt. Tryk på et udsagn og den kasse, det hører til i.',
		},
		boxes: [
			{ id: '1', name: 'Den lovgivende' },
			{ id: '2', name: 'Den udøvende' },
			{ id: '3', name: 'Den dømmende' },
		],
		items: [
			{
				id: '1',
				name: 'Udskriver bøder, arresterer folk mv.',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '2',
				name: 'Vedtager nye love',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'Anklager folk for forbrydelser',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '4',
				name: 'Styrer fængselsvæsenet',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '5',
				name: 'Vurderer, om en lov er blevet overtrådt',
				isPlaced: false,
				correctBoxId: '3',
			},
			{
				id: '6',
				name: 'Vedtager statens årlige budget',
				isPlaced: false,
				correctBoxId: '1',
			},
		],
	},
	{
		id: 'sort-2',
		type: 'sort',
		roomId: 1,
		challengeNumber: 3,
		question: {
			text: 'Hvad gælder om retssager?',
		},
		boxes: [
			{ id: 'true', name: 'Sandt' },
			{ id: 'false', name: 'Falsk' },
		],
		items: [
			{
				id: '1',
				name: 'Retssager er som udgangspunkt offentlige',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '2',
				name: 'Man har som hovedregel ret til at få prøvet en sag ved to instanser',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '3',
				name: 'Anklagemyndigheden skal bevise tiltaltes skyld',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '4',
				name: 'Der bruges lægdommere i alle straffesager',
				isPlaced: false,
				correctBoxId: 'false',
			},
			{
				id: '5',
				name: 'Som vidne må du gerne lyve i retten',
				isPlaced: false,
				correctBoxId: 'false',
			},
		],
	},
	{
		id: 'sort-3',
		type: 'sort',
		roomId: 1,
		challengeNumber: 9,
		question: {
			text: 'De forskellige retter har forskellige roller. Match rollen med den rigtige ret.',
		},
		boxes: [
			{ id: '1', name: 'Byretten' },
			{ id: '2', name: 'Landsretten' },
			{ id: '3', name: 'Højesteret' },
		],
		items: [
			{
				id: '1',
				name: 'Behandler kun sager af principiel betydning',
				isPlaced: false,
				correctBoxId: '3',
			},
			{
				id: '2',
				name: 'En sag starter altid her',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'Dommerne bærer bordeauxrøde kapper',
				isPlaced: false,
				correctBoxId: '3',
			},
			{
				id: '4',
				name: 'Denne ret er oftest første appelinstans',
				isPlaced: false,
				correctBoxId: '2',
			},
		],
	},
	{
		id: 'sort-4',
		type: 'sort',
		roomId: 2,
		challengeNumber: 1,
		question: {
			text: 'Match sagen med den rette afdeling/ret.',
		},
		boxes: [
			{ id: '1', name: 'Notaren' },
			{ id: '2', name: 'Tinglysningsretten' },
			{ id: '3', name: 'Skifteretten' },
		],
		items: [
			{ id: '1', name: 'Dødsbo', isPlaced: false, correctBoxId: '3' },
			{
				id: '2',
				name: 'Registrering af ejerskab af fast ejendom',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '3',
				name: 'Registrering af pant i en bil',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '4',
				name: 'Bekræftelse af underskrifter på vigtige dokumenter',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '5',
				name: 'Udtrækkelse af vindere af større lodtrækninger',
				isPlaced: false,
				correctBoxId: '1',
			},
			{ id: '6', name: 'Gældssanering', isPlaced: false, correctBoxId: '3' },
		],
	},
	// {
	// 	id: 'sort-5',
	// 	type: 'sort',
	// 	roomId: 2,
	// 	challengeNumber: 2,
	// 	question: {
	// 		text: 'Hvor hører udtrykkene til?'
	// 	},
	// 	boxes: [
	// 		{id: '1', name: 'Civilret'},
	// 		{id: '2', name: 'Fogedret'},
	// 		{id: '3', name: 'Strafferet'},
	// 	],
	// 	items: [
	// 		{id: '1', name: 'Sagsøger', isPlaced: false, correctBoxId: '1'},
	// 		{id: '2', name: 'Tiltalte', isPlaced: false, correctBoxId: '3'},
	// 		{id: '3', name: 'Forurettede', isPlaced: false, correctBoxId: '3'},
	// 		{id: '4', name: 'Sagsøgte', isPlaced: false, correctBoxId: '1'},
	// 		{id: '5', name: 'Rekvirent', isPlaced: false, correctBoxId: '2'},
	// 		{id: '6', name: 'Rekvisitus', isPlaced: false, correctBoxId: '2'},
	// 		{id: '7', name: 'Anklager', isPlaced: false, correctBoxId: '3'},
	// 		{id: '8', name: 'Forsvarer', isPlaced: false, correctBoxId: '3'},
	// 		// {id: '9', name: 'Advokat', isPlaced: false, correctBoxId: '1'}
	// 	]
	// },
	{
		id: 'sort-6',
		type: 'sort',
		roomId: 3,
		challengeNumber: 9,
		question: {
			text: 'Sorter, alt efter om det er rigtigt for EU-domstolen eller menneskerettighedsdomstolen.',
		},
		boxes: [
			{ id: '1', name: 'EU-Domstolen (European Court of Justice)' },
			{
				id: '2',
				name: 'Menneske&shy;rettighedsdomstolen (European Court of Human Rights)',
			},
		],
		items: [
			{
				id: '1',
				name: 'Hører under Europarådet',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '2',
				name: 'Har hovedsæde i Luxembourg',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'Sikrer medlemsstaternes overholdelse af EU-lovgivning',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '4',
				name: '47 medlemsstater',
				isPlaced: false,
				correctBoxId: '2',
			},
		],
	},
	{
		id: 'sort-7',
		type: 'sort',
		roomId: 3,
		challengeNumber: 18,
		question: {
			text: "EU's lande er retsstater. Hvad kendetegner en retsstat?",
		},
		boxes: [
			{ id: 'true', name: 'Sandt' },
			{ id: 'false', name: 'Falsk' },
		],
		items: [
			{
				id: '1',
				name: 'Forbud mod vilkårlig magtudøvelse',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '2',
				name: 'Magthaverne kontrolleres af uafhængige domstole',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '3',
				name: 'Respekt for grundlæggende rettigheder',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '4',
				name: 'Højesteret godkender alle love',
				isPlaced: false,
				correctBoxId: 'false',
			},
			{
				id: '5',
				name: 'Dommere er politisk valgt',
				isPlaced: false,
				correctBoxId: 'false',
			},
		],
	},
	{
		id: 'sort-8',
		type: 'sort',
		roomId: 4,
		challengeNumber: 6,
		question: {
			text: "Some countries are governed by the Rule of Law. What characterises such countries?",
		},
		boxes: [
			{ id: 'true', name: 'TRUE' },
			{ id: 'false', name: 'FALSE' },
		],
		items: [
			{
				id: '1',
				name: 'Prohibition of arbitrary exercise of executive power',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '2',
				name: 'Effective judicial protection by independent and impartial courts',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '3',
				name: 'Respect for fundamental rights, separation of powers, and equality before the law',
				isPlaced: false,
				correctBoxId: 'true',
			},
			{
				id: '4',
				name: 'Minimum 5 courts per 100,000 citizens',
				isPlaced: false,
				correctBoxId: 'false',
			},
			{
				id: '5',
				name: 'The right of the government to overrule any court judgement',
				isPlaced: false,
				correctBoxId: 'false',
			},
		],
	},
	{
		id: 'sort-9',
		type: 'sort',
		roomId: 4,
		challengeNumber: 11,
		question: {
			text: "Often the European Union and the Council of Europe are being confused with each other. Match the statement to the right organization",
		},
		boxes: [
			{ id: '1', name: 'European Union' },
			{ id: '2', name: 'The Council of Europe' },
		],
		items: [
			{
				id: '1',
				name: 'A political and economic union',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '2',
				name: 'Can make binding laws that apply directly in the member states',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'Enforces the European Convention on Human Rights',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '4',
				name: 'Was founded after WWII to uphold human rights, democracy and the Rule of Law',
				isPlaced: false,
				correctBoxId: '2',
			}
		],
	},
	{
		id: 'sort-10',
		type: 'sort',
		roomId: 6,
		challengeNumber: 6,
		question: {
			text: "Sorter efter, om denne situation er omfattet af korruption efter den danske straffelov?",
		},
		boxes: [
			{ id: '1', name: 'Korruption' },
			{ id: '2', name: 'Ikke korruption' },
		],
		items: [
			{
				id: '1',
				name: 'En person i offentlig ansættelse har jubilæum og modtager en æske chokolade af en virksomhed',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '2',
				name: 'En offentlig ansat bilprøveassistent modtager 500 kr. for at godkende en bil til syn til trods for fejl og mangler',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'En ansat i ministeriet køber en IPAD til 50% af dets oprindelige pris af et IT-selsskab, som ministeriet har som fast kunde',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '4',
				name: 'En offentlig ansat udtrykker sin begejstring overfor en privat virksomheds indretning',
				isPlaced: false,
				correctBoxId: '2',
			}
		],
	},
	{
		id: 'sort-11',
		type: 'sort',
		roomId: 6,
		challengeNumber: 3,
		question: {
			text: "Sorter efter, om udsagnet er sandt eller falsk ifølge Danida og Transparency International",
		},
		boxes: [
			{ id: '1', name: 'Sandt' },
			{ id: '2', name: 'falsk' },
		],
		items: [
			{
				id: '1',
				name: 'Korruption rammer fattige hårdere end rige',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '2',
				name: 'Korruption foregår i det åbne',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '3',
				name: 'På verdensplan er Europa det mindst korrupte kontinent i verden',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '4',
				name: 'Corona-krisen er ikke alene en sundheds- og økonomisk krise, men også en korruptionskrise',
				isPlaced: false,
				correctBoxId: '1',
			},
			
		],
	},
	{
		id: 'sort-12',
		type: 'sort',
		roomId: 6,
		challengeNumber: 9,
		question: {
			text: "Sortér efter, hvad der er korrekt opførsel for en dommer?",
		},
		boxes: [
			{ id: '1', name: 'Sandt' },
			{ id: '2', name: 'FALSK' },
		],
		items: [
			{
				id: '1',
				name: 'En dommer skal alene rette sig efter loven',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '2',
				name: 'En dommer har ytringsfrihed ',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'En dommer må gerne dømme i en sag, hvor han er i tæt familie med én af parterne',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '4',
				name: 'En dommer skal træffe beslutninger i overensstemmelse med regeringens politik',
				isPlaced: false,
				correctBoxId: '2',
			},
			
		],
	},
	{
		id: 'sort-13',
		type: 'sort',
		roomId: 5,
		challengeNumber: 6,
		question: {
			text: 'Sort according to whether the following situation comes under the designation of "corruption"',
		},
		boxes: [
			{ id: '1', name: 'Corruption' },
			{ id: '2', name: 'Not Corruption' },
		],
		items: [
			{
				id: '1',
				name: 'A public employee expresses enthusiasm about a private company’s interior design',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '2',
				name: 'A person in public employment has an anniversary and receives a box of chocolates from a firm',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '3',
				name: 'A publicly employed car testing assistant receives DKK 500 to approve a car for inspection despite faults and deficiencies',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '4',
				name: 'An employee of a ministry buys an iPad for 50% of its original price from an IT company that the ministry has as a regular customer',
				isPlaced: false,
				correctBoxId: '1',
			}
		],
	},
	{
		id: 'sort-14',
		type: 'sort',
		roomId: 5,
		challengeNumber: 3,
		question: {
			text: "Sort according to whether the statement is true or false according to Danida and Transparency International? ",
		},
		boxes: [
			{ id: '1', name: 'True' },
			{ id: '2', name: 'False' },
		],
		items: [
			{
				id: '1',
				name: 'Corruption hits the poor harder than the rich',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '2',
				name: 'Corruption takes place in the open',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '3',
				name: 'Europe is the least corrupt continent in the world',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '4',
				name: 'The COVID-19 crisis is not only a health and economy crisis, but also a corruption crisis',
				isPlaced: false,
				correctBoxId: '1',
			},					
		],
	},
	{
		id: 'sort-15',
		type: 'sort',
		roomId: 5,
		challengeNumber: 9,
		question: {
			text: "Sort according to whether the statement is true or false",
		},
		boxes: [
			{ id: '1', name: 'True' },
			{ id: '2', name: 'False' },
		],
		items: [
			{
				id: '1',
				name: 'A judge must judge on the law alone',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '2',
				name: 'A judge has freedom of speech',
				isPlaced: false,
				correctBoxId: '1',
			},
			{
				id: '3',
				name: 'A judge is allowed to try a case in which a close family member is one of the parties',
				isPlaced: false,
				correctBoxId: '2',
			},
			{
				id: '4',
				name: 'A judge must make decisions in agreement with government policy',
				isPlaced: false,
				correctBoxId: '2',
			},					
		],
	},
];

export { sortData };
