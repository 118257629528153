// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react';
import useStateCallback from 'helpers/use-state-callback';
import appConfig from 'config/app.config';
import { shuffleArray } from 'helpers/array-helper';
import { orderPoints, streakPoints } from 'data/points-data';
import { getIconType } from 'helpers/points-helper';
import Order from './order';
import { IResolveStatus } from '../challenge-controller';
import { IChallenge, IPlayerData } from 'components/game/game-controller';
import { IOrderBox } from 'data/order-data';

interface IProps {
	roomId: number;
	challengeData: IChallenge;
	playerData: IPlayerData;
	toggleStreakPopup: (
		showStreakPopup: boolean,
		challengeCompleted?: boolean | undefined,
		isLastChallengeInRoom?: boolean | undefined,
		newPointsTierUnlocked?: boolean | undefined
	) => void;
	completeChallenge: (
		challengeId: string,
		points: number,
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		playerChallenges: any,
		playerStreaks: any,
		playerpoints: any
	) => Promise<null>;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
}

const OrderController: React.FC<IProps> = ({
	roomId,
	challengeData,
	playerData,
	toggleStreakPopup,
	updatePlayerData,
	completeChallenge,
}: IProps) => {
	const [orderControllerState, setOrderControllerState] = useStateCallback({
		isLoading: true,
		isPaused: false,
		animation: null,
		orderData: null,
		selectedItemId: null,
	});

	/**
	 * Load order & sync with player
	 */
	const loadChallenge = () => {
		/* Get order data */
		const parsedChallengeData = JSON.parse(JSON.stringify(challengeData));
		parsedChallengeData.completed = false;
		parsedChallengeData.errors = 0;

		/* Shuffle itmes */
		parsedChallengeData.items = shuffleArray(parsedChallengeData.items);

		/* Sync with player progress */
		if (
			playerData.hasOwnProperty('challenges') &&
			playerData.challenges.some((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})
		) {
			const playerChallengeData = playerData.challenges.filter((challenge) => {
				return challenge.id === parsedChallengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				parsedChallengeData.completed = playerChallengeData.completed;
			}
			parsedChallengeData.errors = playerChallengeData.errors;
			parsedChallengeData.boxes.forEach((box: IOrderBox) => {
				if (playerChallengeData.orderedItems.indexOf(box.correctItemId) >= 0) {
					box.placedItemId = box.correctItemId;
				}
			});
		}

		/* Update state */
		setOrderControllerState(
			{
				...orderControllerState,
				isLoading: false,
				isPaused: parsedChallengeData.completed,
				orderData: parsedChallengeData,
			},
			() => {
				/* Adjust body height */
				const headerElement = document.getElementById('OrderHeader');
				const bodyElement = document.getElementById('OrderBody');
				if (headerElement && bodyElement) {
					const fontSize = 16 * (headerElement.clientWidth / 360);
					const halfFontSize = 0.5 * fontSize;
					const headerHeight = headerElement.clientHeight + halfFontSize;
					bodyElement.style.paddingTop = headerHeight + 'px';
				}
			}
		);
	};

	/**
	 * Select item
	 * @param {string} itemId
	 */
	const handleSelectItem = (itemId: string) => {
		if (orderControllerState.isPaused) return;

		setOrderControllerState({ ...orderControllerState, isPaused: true }, () => {
			const theSelectedItemId =
				orderControllerState.selectedItemId === itemId ? null : itemId;
			setOrderControllerState({
				...orderControllerState,
				isPaused: false,
				selectedItemId: theSelectedItemId,
			});
		});
	};

	/**
	 * Select box
	 * @param {number} boxIndex
	 */
	const handleSelectBox = (boxIndex: number) => {
		if (
			orderControllerState.isPaused ||
			orderControllerState.selectedItemId === null
		) {
			return;
		}
		if (orderControllerState.orderData.boxes[boxIndex].placedItemId !== null) {
			return;
		}
		setOrderControllerState({ ...orderControllerState, isPaused: true }, () => {
			const parsedChallengeData = JSON.parse(
				JSON.stringify(orderControllerState.orderData)
			);
			const isCorrect =
				parsedChallengeData.boxes[boxIndex].correctItemId ===
				orderControllerState.selectedItemId;

			/* Update game data */
			if (!isCorrect) {
				/* Wrong placement */
				parsedChallengeData.errors = parsedChallengeData.errors + 1;
				setOrderControllerState({
					...orderControllerState,
					animation: 'wrong',
					orderData: parsedChallengeData,
				});
			} else {
				/* Correct placement */
				parsedChallengeData.boxes[boxIndex].placedItemId =
					orderControllerState.selectedItemId;
				setOrderControllerState({
					...orderControllerState,
					animation: 'correct',
					orderData: parsedChallengeData,
				});
			}

			/* Player data - progress and errors */
			let playerChallenges: any[] = [];
			const orderedItems: string[] = [];
			parsedChallengeData.boxes.forEach((box: IOrderBox) => {
				if (box.placedItemId) orderedItems.push(box.placedItemId);
			});
			if (playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(playerData.challenges));
			}
			// const playerChallengeIndex = playerChallenges.findIndex(
			// 	(challenge: IChallenge) => {
			// 		return challenge.id === parsedChallengeData.id;
			// 	}
			// );
			// Refactor for IE
			let playerChallengeIndex = -1;
			// eslint-disable-next-line array-callback-return
			playerChallenges.some((challenge: IChallenge, i: number) => {
				if (challenge.id === parsedChallengeData.id) {
					playerChallengeIndex = i;
					return true;
				}
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: parsedChallengeData.id,
					completed: false,
					errors: parsedChallengeData.errors,
					orderedItems: orderedItems,
				});
			} else {
				playerChallenges[playerChallengeIndex].errors =
					parsedChallengeData.errors;
				playerChallenges[playerChallengeIndex].orderedItems = orderedItems;
			}

			/* Player data - points */
			const playerPoints = JSON.parse(JSON.stringify(playerData.points));

			/* Player data - streaks */
			let streakUnlocked = false;
			let newPointsTierUnlocked = false;
			let playerStreaks: any = {};
			if (appConfig.useStreaks) {
				if (playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(playerData.streaks));
				}

				if (!playerStreaks.hasOwnProperty(`room${roomId.toString()}`)) {
					playerStreaks[`room${roomId.toString()}`] = 0;
				}
				if (
					playerStreaks[`room${roomId.toString()}`] !== -1 ||
					!appConfig.limitStreaksPerRoom
				) {
					if (isCorrect) {
						playerStreaks[`room${roomId.toString()}`] =
							playerStreaks[`room${roomId.toString()}`] + 1;
					} else {
						playerStreaks[`room${roomId.toString()}`] = 0;
					}
					const roomStreak = parseInt(
						playerStreaks[`room${roomId.toString()}`]
					);
					if (roomStreak === streakPoints.streakMarker) {
						streakUnlocked = true;
						const prevPlayerPoints = playerPoints[`room${roomId.toString()}`];
						playerPoints[`room${roomId.toString()}`] =
							playerPoints[`room${roomId.toString()}`] +
							streakPoints.streakPoints;
						newPointsTierUnlocked =
							getIconType(prevPlayerPoints, roomId) !==
							getIconType(playerPoints[`room${roomId.toString()}`], roomId);
						if (appConfig.limitStreaksPerRoom) {
							playerStreaks[`room${roomId.toString()}`] = -1;
						} else {
							playerStreaks[`room${roomId.toString()}`] = 0;
						}
					}
				}
			}

			/* Update player data */
			updatePlayerData({
				...playerData,
				challenges: playerChallenges,
				streaks: playerStreaks,
				points: playerPoints,
			}).then((response) => {
				if (response.status === 'ok') {
					setTimeout(() => {
						/* Check if all items have been ordered */
						const challengeIsComplete = !parsedChallengeData.boxes.some(
							(box: IOrderBox) => {
								return box.placedItemId === null;
							}
						);

						if (challengeIsComplete) {
							/* Update game state, handle challenge completed */
							setOrderControllerState({
								...orderControllerState,
								selectedItemId: null,
								animation: null,
								orderData: parsedChallengeData,
							});
							orderingCompleted(
								streakUnlocked,
								newPointsTierUnlocked,
								parsedChallengeData,
								playerChallenges,
								playerStreaks,
								playerPoints
							);
						} else {
							/* Update game state, show streak popup */
							setOrderControllerState({
								...orderControllerState,
								isPaused: false,
								selectedItemId: null,
								animation: null,
								orderData: parsedChallengeData,
							});
							if (streakUnlocked) {
								toggleStreakPopup(true, false, false, newPointsTierUnlocked);
							}
						}
					}, 500);
				} else {
					console.log('error');
				}
			});
		});
	};

	/**
	 * Order completed
	 * @param {bool} streakUnlocked
	 */
	const orderingCompleted = (
		streakUnlocked: boolean,
		newPointsTierUnlocked: boolean,
		challengeData: any,
		playerChallenges: any,
		playerStreaks: any,
		playerPoints: any
	) => {
		const parsedChallengeData = { ...challengeData };
		parsedChallengeData.completed = true;

		let points = orderPoints.minPoints;
		// const pointIndex = orderPoints.pointLimits.findIndex((limit) => {
		// 	return parsedChallengeData.errors <= limit;
		// });
		// Refactor for IE
		let pointIndex = -1;
		// eslint-disable-next-line array-callback-return
		orderPoints.pointLimits.some((limit, i) => {
			if (parsedChallengeData.errors <= limit) {
				pointIndex = i;
				return true;
			}
		});
		if (pointIndex >= 0) points = orderPoints.pointValues[pointIndex];

		completeChallenge(
			parsedChallengeData.id,
			points,
			streakUnlocked,
			newPointsTierUnlocked,
			playerChallenges,
			playerStreaks,
			playerPoints
		).then(() => {
			setOrderControllerState({
				...orderControllerState,
				selectedItemId: null,
				orderData: parsedChallengeData,
			});
		});
	};

	useEffect(() => {
		// Component mounted
		// Load challenge
		loadChallenge();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setOrderControllerState(
			{ ...orderControllerState, isLoading: true, orderData: null },
			() => {
				loadChallenge();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeData.id]);
	if (!orderControllerState.isLoading && orderControllerState.orderData) {
		return (
			<Order
				isPaused={orderControllerState.isPaused}
				selectedItemId={orderControllerState.selectedItemId}
				animation={orderControllerState.animation}
				orderData={orderControllerState.orderData}
				handleSelectItem={handleSelectItem}
				handleSelectBox={handleSelectBox}
			/>
		);
	}
	return null;
};

export default OrderController;
