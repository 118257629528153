let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let url = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let firebaseConfig = {};

if (env === 'development' || env === 'test') {
	firebaseConfig = {
		apiKey: 'AIzaSyCirbvpUm_cM_I-pDS6Hdl8ADDsQqMqdRQ',
		authDomain: 'cgl-courtroomgame-test.firebaseapp.com',
		databaseURL: 'https://cgl-courtroomgame-test.firebaseio.com',
		projectId: 'cgl-courtroomgame-test',
		storageBucket: 'cgl-courtroomgame-test.appspot.com',
		messagingSenderId: '717864760998',
		appId: '1:717864760998:web:10442247b8eefc2431a667'
	};
}

if (env === 'test') {
	url = 'https://cgl-courtroomgame-test.web.app';
	apiURL = 'https://cgl-courtroomgame-test.web.app/api/';
}
if (env === 'production') {
	url = 'https://domstolsdysten.dk';
	apiURL = 'https://cgl-courtroomgame-production.web.app/api/';
	firebaseConfig = {
		apiKey: 'AIzaSyCy47urMHFh9oeiP3EGeNsBGtyC6FZ_gl4',
		authDomain: 'cgl-courtroomgame-production.firebaseapp.com',
		databaseURL: 'https://cgl-courtroomgame-production.firebaseio.com',
		projectId: 'cgl-courtroomgame-production',
		storageBucket: 'cgl-courtroomgame-production.appspot.com',
		messagingSenderId: '311083672788',
		appId: '1:311083672788:web:4f68dc384c3b23aeb58741',
		measurementId: 'G-W76YGTHTLP'
	};
}


const appConfig = {
	env: env,
	firebaseConfig: firebaseConfig,
	name: 'Domstolsdysten',
	url,
	apiURL: apiURL,
	useStreaks: true,
	limitStreaksPerRoom: false,
	cookiesAcceptedCookieName: 'courtroomgame_cookies_accepted',
	playerDataCookieName: 'courtroomgame_player_data',
	availabilityStatementLink: 'https://www.was.digst.dk/domstolsdysten-dk'
};

export default appConfig;
