/* TEMPLATE
{
	id: '',
	type: 'pairs',
	roomId: 1,
	challengeNumber: 1,
	question: {
		text: 'blah?'
	},
	columnA: [
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
	],
	columnB: [
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
	]
},
*/
export interface IPairsQuestion {
	text: string;
}
export interface IPairscolumnAItem {
	id: string;
	text: string;
	image: string | null;
	isSelected: boolean;
	connectedTo: string | null;
	correctConnectionId: string;
}
export interface IPairscolumnBItem {
	id: string;
	text: string;
	image: string | null;
	isSelected: boolean;
}
export interface IPairsData {
	id: string;
	type: string;
	roomId: number;
	challengeNumber: number;
	question: IPairsQuestion;
	columnA: IPairscolumnAItem[];
	columnB: IPairscolumnBItem[];
	completed?: boolean;
}

const pairsData: IPairsData[] = [
	// {
	// 	id: 'pairs-1',
	// 	type: 'pairs',
	// 	roomId: 1,
	// 	challengeNumber: 4,
	// 	question: {
	// 		text: 'Byretterne har afdelinger som behandler forskellige typer af sager. Match sagen med den afdeling, der behandler den.'
	// 	},
	// 	columnA: [
	// 		{id: '1', text: 'Sager om dødsboer', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1'},
	// 		{id: '2', text: 'Sager om voldtægt', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2'},
	// 		{id: '3', text: 'Tvangsauktioner', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3'},
	// 		{id: '4', text: 'Samværssager', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4'},
	// 		{id: '5', text: 'Sager med en værdi under 50.000 kr.', image: null, isSelected: false, connectedTo: null, correctConnectionId: '5'},
	// 		{id: '6', text: 'Underskrift af testamente', image: null, isSelected: false, connectedTo: null, correctConnectionId: '6'}
	// 	],
	// 	columnB: [
	// 		{id: '1', text: 'Skifteretten', image: null, isSelected: false},
	// 		{id: '2', text: 'Strafferetten', image: null, isSelected: false},
	// 		{id: '3', text: 'Fogedretten', image: null, isSelected: false},
	// 		{id: '4', text: 'Familieretten', image: null, isSelected: false},
	// 		{id: '5', text: 'Civilretten', image: null, isSelected: false},
	// 		{id: '6', text: 'Notaren', image: null, isSelected: false}
	// 	]
	// },
	{
		id: 'pairs-2',
		type: 'pairs',
		roomId: 2,
		challengeNumber: 4,
		question: {
			text: 'Domstolene behandler forskellige typer af sager. Match sagerne i venstre side med sagstyperne i højre.',
		},
		columnA: [
			{
				id: '1',
				text: 'Sager om uenighed mellem to borgere',
				image: null,
				isSelected: false,
				connectedTo: null,
				correctConnectionId: '1',
			},
			{
				id: '2',
				text: 'Sager, som efterforskes af politiet',
				image: null,
				isSelected: false,
				connectedTo: null,
				correctConnectionId: '2',
			},
			{ id: '3', text: 'Sager om arv', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3' },
			{
				id: '4',
				text: 'Sager om tvangsauktion',
				image: null,
				isSelected: false,
				connectedTo: null,
				correctConnectionId: '4',
			},
		],
		columnB: [
			{ id: '1', text: 'Civile sager', image: null, isSelected: false },
			{ id: '2', text: 'Straffesager', image: null, isSelected: false },
			{ id: '3', text: 'Skiftesager', image: null, isSelected: false },
			{ id: '4', text: 'Fogedsager', image: null, isSelected: false },
		],
	},
	{
		id: 'pairs-3',
		type: 'pairs',
		roomId: 2,
		challengeNumber: 19,
		question: {
			text: 'Match forbrydelsen med strafferammen i loven.',
		},
		columnA: [
			{ id: '1', text: 'Vold', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1' },
			{ id: '2', text: 'Hjemmerøveri', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2' },
			{
				id: '3',
				text: 'Grov økonomisk kriminalitet',
				image: null,
				isSelected: false,
				connectedTo: null,
				correctConnectionId: '3',
			},
			{ id: '4', text: 'Grov vold', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4' },
			// {id: '5', text: 'Gaderøveri', image: null, isSelected: false, connectedTo: null, correctConnectionId: '5'}, // TODO
		],
		columnB: [
			{ id: '1', text: 'Op til 3 års fængsel', image: null, isSelected: false },
			{ id: '2', text: 'Op til 10 års fængsel', image: null, isSelected: false },
			{ id: '3', text: 'Op til 8 års fængsel', image: null, isSelected: false },
			{ id: '4', text: 'Op til 6 års fængsel', image: null, isSelected: false },
			// {id: '5', text: 'Op til 6 års fængsel', image: null, isSelected: false},
		],
	},
	{
		id: 'pairs-4',
		type: 'pairs',
		roomId: 2,
		challengeNumber: 20,
		question: {
			text: 'Match forbrydelsen med strafferammen i loven.',
		},
		columnA: [
			{ id: '1', text: 'Butikstyveri', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1' },
			{
				id: '2',
				text: 'Voldtægt af særligt farlig karakter',
				image: null,
				isSelected: false,
				connectedTo: null,
				correctConnectionId: '2',
			},
			{
				id: '3',
				text: 'Hærværk af betydeligt eller systematisk omfang',
				image: null,
				isSelected: false,
				connectedTo: null,
				correctConnectionId: '3',
			},
			// {id: '4', text: 'Hærværk', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4'}, // TODO
			{ id: '5', text: 'Drab', image: null, isSelected: false, connectedTo: null, correctConnectionId: '5' },
		],
		columnB: [
			{ id: '1', text: 'Op til 1 ½ års fængsel', image: null, isSelected: false },
			{ id: '2', text: 'Op til 12 års fængsel', image: null, isSelected: false },
			{ id: '3', text: 'Op til 6 års fængsel', image: null, isSelected: false },
			// {id: '4', text: '', image: null, isSelected: false},
			{ id: '5', text: 'Fra 5 år indtil på livstid', image: null, isSelected: false },
		],
	},
	// {
	// 	id: 'pairs-5',
	// 	type: 'pairs',
	// 	roomId: 3,
	// 	challengeNumber: 8,
	// 	question: {
	// 		text: 'Match domstolen til organisationen.'
	// 	},
	// 	columnA: [
	// 		{id: '1', text: 'FN', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1'},
	// 		{id: '2', text: 'EU', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2'},
	// 		// {id: '3', text: 'FN', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3'}, // TODO
	// 		{id: '4', text: 'Europarådet', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4'},
	// 	],
	// 	columnB: [
	// 		{id: '1', text: 'The International Criminal Court (krigsforbryder&#8208;domstolen)', image: null, isSelected: false},
	// 		{id: '2', text: 'The Court of Justice of the European Union (EU domstolen)', image: null, isSelected: false},
	// 		// {id: '3', text: 'The International Court of Justice (Den Internationale Domstol) (kun sager mellem stater)', image: null, isSelected: false},
	// 		{id: '4', text: 'The Europen Court of Human Rights (menneskerettig&#8208;hedsdomstolen)', image: null, isSelected: false},
	// 	]
	// },
	{
		id: 'pairs-6',
		type: 'pairs',
		roomId: 3,
		challengeNumber: 10,
		question: {
			text: 'Match antallet af medlemmer med organisationen.',
		},
		columnA: [
			{ id: '1', text: '27', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1' },
			{ id: '2', text: '47', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2' },
			{ id: '3', text: '193', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3' },
		],
		columnB: [
			{ id: '1', text: 'EU', image: null, isSelected: false },
			{ id: '2', text: 'Europarådet', image: null, isSelected: false },
			{ id: '3', text: 'FN', image: null, isSelected: false },
		],
	},
	// {
	// 	id: 'pairs-7',
	// 	type: 'pairs',
	// 	roomId: 3,
	// 	challengeNumber: 11,
	// 	question: {
	// 		text: 'Sæt EU dokumentet sammen med den rigtige beskrivele af dokumentet.'
	// 	},
	// 	columnA: [
	// 		{id: '1', text: 'Direktiv', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1'},
	// 		{id: '2', text: 'Forordning', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2'},
	// 		{id: '3', text: 'Henstilling', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3'},
	// 	],
	// 	columnB: [
	// 		{id: '1', text: 'Fastsætter mål, men det er op til hvert enkelt medlemsland at bestemme, hvordan det vil opnå målene', image: null, isSelected: false},
	// 		{id: '2', text: 'Gælder direkte i medlemslandene, også selvom Folketinget ikke har vedtaget den', image: null, isSelected: false},
	// 		{id: '3', text: 'Er ikke bindende. EU giver her sin mening til kende og foreslår nye retningslinjer ', image: null, isSelected: false},
	// 	]
	// },

	{
		id: 'pairs-7',
		type: 'pairs',
		roomId: 4,
		challengeNumber: 10,
		question: {
			text: 'Match the Court to the right organization',
		},
		columnA: [
			{ id: '1', text: 'UN', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1' },
			{ id: '2', text: 'EU', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2' },
			{ id: '3', text: 'Council of Europe', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3' },
		],
		columnB: [
			{ id: '1', text: 'The International Criminal Court and the International Court of Justice', image: null, isSelected: false },
			{ id: '2', text: 'The Court of Justice of the European Union', image: null, isSelected: false },
			{ id: '3', text: 'The European Court of Human Rights', image: null, isSelected: false },
		],
	},
	{
		id: 'pairs-8',
		type: 'pairs',
		roomId: 6,
		challengeNumber: 5,
		question: {
			text: 'Match udsagn fra Transparency International med det rigtige kontinent',
		},
		columnA: [
			{ id: '1', text: '3 ud af 10 personer anvender bestikkelse eller bruger en personlig forbindelse til at få adgang til en offentlig tjeneste', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1' },
			{ id: '2', text: '25% anvender bestikkelse for at modtage basale ting som el, vand og medicin', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2' },
			{ id: '3', text: 'Cirka 33% tror, at de fleste regeringsmedlemmer i deres land er korrupte', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3' },
			{ id: '4', text: '53% af de adpsurgte mener, at korruption i deres land er steget det seneste år', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4' },
		],
		columnB: [
			{ id: '1', text: 'Europa', image: null, isSelected: false },
			{ id: '2', text: 'Afrika', image: null, isSelected: false },
			{ id: '3', text: 'Asien', image: null, isSelected: false },
			{ id: '4', text: 'Latinamerika og Carribien', image: null, isSelected: false },
		],
	},
	{
		id: 'pairs-9',
		type: 'pairs',
		roomId: 5,
		challengeNumber: 5,
		question: {
			text: 'Match the facts with the right continent',
		},
		columnA: [
			{ id: '1', text: '3 out of 10 people use bribery or a personal connection to gain access to a public service', image: null, isSelected: false, connectedTo: null, correctConnectionId: '2' },
			{ id: '2', text: '25% use bribery to receive basic things such as electricity, water, and medicine', image: null, isSelected: false, connectedTo: null, correctConnectionId: '1' },
			{ id: '3', text: 'Roughly 33% believe that most of the government members in their country are corrupt', image: null, isSelected: false, connectedTo: null, correctConnectionId: '3' },
			{ id: '4', text: '53% of respondents believe that corruption in their country has risen in recent years', image: null, isSelected: false, connectedTo: null, correctConnectionId: '4' },
		],
		columnB: [
			{ id: '1', text: 'Africa', image: null, isSelected: false },
			{ id: '2', text: 'Europe', image: null, isSelected: false },
			{ id: '3', text: 'Asia', image: null, isSelected: false },
			{ id: '4', text: 'Latin America and the Caribbian', image: null, isSelected: false },
		],
	},
];

export { pairsData };
