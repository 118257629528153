// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import { challengeUiTexts } from 'data/ui-texts';
import BackButton from 'components/layout/back-button';
import ChallengeController, { IResolveStatus } from 'components/challenges/challenge-controller';
import { IChallenge, IPlayerData } from 'components/game/game-controller';
import { IRoom } from 'components/room-overview/room-overview-controller';
import Popup from 'components/layout/popup';
import './room.scss';
import { getIconData } from 'helpers/points-helper';
import { IGState, useGlobal } from 'components/game/game-controller';
import { isRoomCompleted, areAllRoomsCompleted } from 'helpers/progress-helper';

interface IProps {
	roomStreakUnlocked: boolean;
	showRoomCompletedPopup: boolean;
	roomId: number;
	roomData: IRoom | null;
	roomChallengesData: any[];
	challengesData: IChallenge[];
	allRoomsChallengesData: { room1: any[]; room2: any[]; room3: any[]; room4: any[]; room5: any[]; room6: any[] };
	playerData: IPlayerData;
	goToPage: (pageId: string | null, roomId?: number | null) => void;
	goToPreviousPage: () => void;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
	handleRoomComplete: (roomStreakUnlocked: boolean) => void;
	handleResetRoom: () => void;
	updateStats: (type: string, roomId: number) => void;
}

const Room: React.FC<IProps> = ({
	roomStreakUnlocked,
	showRoomCompletedPopup,
	roomId,
	roomChallengesData,
	challengesData,
	allRoomsChallengesData,
	playerData,
	goToPage,
	goToPreviousPage,
	updatePlayerData,
	handleRoomComplete,
	handleResetRoom,
	updateStats
}: IProps) => {

	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [challengeUiTextsLang, setChallengeUiTextsLang] = React.useState({...challengeUiTexts.dk});
	React.useEffect(() => {
		setChallengeUiTextsLang(currentlang === 'en' ? challengeUiTexts.en : challengeUiTexts.dk );
	}, [currentlang]);

	const [roomsCompleted, setRoomsCompleted] = useState({
		room1: false,
		room2: false,
		room3: false,
		room4: false,
		room5: false,
		room6: false,
	});
	useEffect(() => {
		setRoomsCompleted({
			room1: isRoomCompleted(allRoomsChallengesData['room1'], playerData),
			room2: isRoomCompleted(allRoomsChallengesData['room2'], playerData),
			room3: isRoomCompleted(allRoomsChallengesData['room3'], playerData),
			room4: isRoomCompleted(allRoomsChallengesData['room4'], playerData),
			room5: isRoomCompleted(allRoomsChallengesData['room5'], playerData),
			room6: isRoomCompleted(allRoomsChallengesData['room6'], playerData),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoomsChallengesData]);

	if (roomChallengesData.length === 0) {
		let noChallengesText = JSON.parse(JSON.stringify(challengeUiTextsLang.noChallenges));
		noChallengesText = noChallengesText.replace(/%roomId%/g, roomId);
		return (
			<div className="Room">
				<div className="Room-header">
					<BackButton type="rooms" roomId={roomId} goToPreviousPage={goToPreviousPage} />
				</div>
				<div className="Room-body">{noChallengesText}</div>
			</div>
		);
	}

	const totalPoints = JSON.parse(JSON.stringify(playerData.points));
	const pnts = totalPoints[`room${roomId.toString()}`];

	let roomCompletedTitle = null;
	let roomCompletedText = '';
	let roomCompleteIconData = null;
	if (showRoomCompletedPopup) {
		roomCompletedTitle = JSON.parse(JSON.stringify(challengeUiTextsLang.roomCompletedPopup.title[roomId - 1])).replace(
			/%points%/g,
			pnts.toString()
		);
		roomCompletedText = JSON.parse(JSON.stringify(challengeUiTextsLang.roomCompletedPopup.texts[roomId - 1]));
		roomCompleteIconData = getIconData(pnts, roomId);
	}

	return (
		<div className="Room">
			{/* Challenge */}
			{!showRoomCompletedPopup && (
				<ChallengeController
					roomId={roomId}
					roomChallengesData={roomChallengesData}
					playerData={playerData}
					goToPage={goToPage}
					goToPreviousPage={goToPreviousPage}
					updatePlayerData={updatePlayerData}
					handleRoomComplete={handleRoomComplete}
					updateStats={updateStats}
				/>
			)}

			{/* Room completed popup */}
			<Popup
				roomId={roomId}
				showPopup={showRoomCompletedPopup}
				type="roomCompleted"
				goToPage={goToPage}
				title={roomCompletedTitle}
				text={roomCompletedText}
				iconData={roomCompleteIconData}
				points={totalPoints[`room${roomId.toString()}`]}
				roomsCompleted={roomsCompleted}
				allCompleted={areAllRoomsCompleted(challengesData, playerData)}
				handleResetRoom={handleResetRoom}
			/>
		</div>
	);
};

export default Room;
