// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import appConfig from 'config/app.config';
import { challengeUiTexts } from 'data/ui-texts';
import { IGState, useGlobal } from 'components/game/game-controller';
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
} from 'react-share';
import './popup.scss';

interface IProps {
	roomId: number;
	pointTierTitle: string;
}
const ShareButtons: React.FC<IProps> = ({ roomId, pointTierTitle }: IProps) => {

	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [challengeUiTextsLang, setChallengeUiTextsLang] = React.useState({...challengeUiTexts.dk});
	React.useEffect(() => {
		setChallengeUiTextsLang(currentlang === 'en' ? challengeUiTexts.en : challengeUiTexts.dk );
	}, [currentlang]);
	
	const iconsStyles = {
		borderRadius: '5px',
		width: '40px',
		height: '40px',
		margin: '0.5em',
	};

	const shareText =
		JSON.parse(
			JSON.stringify(challengeUiTextsLang.roomCompletedPopup.shareTexts[roomId - 1])
		) +
		' ' +
		pointTierTitle;

	return (
		<div className="Social-Buttons">
			<FacebookShareButton
				url={appConfig.url}
				quote={shareText}
				hashtag={'#domstolsdysten'}
			>
				<FacebookIcon
					bgStyle={{ fill: '#888279' }}
					iconFillColor="#F3F0E0"
					style={{ ...iconsStyles }}
					className="socialIcons"
				/>
			</FacebookShareButton>
			<TwitterShareButton
				url={appConfig.url}
				title={shareText}
				hashtags={['domstolsdysten']}
			>
				<TwitterIcon
					bgStyle={{ fill: '#888279' }}
					iconFillColor="#F3F0E0"
					style={{ ...iconsStyles }}
					className="socialIcons"
				/>
			</TwitterShareButton>
		</div>
	);
};

export default ShareButtons;
