// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import BackButton from 'components/layout/back-button';
import iconPlay from 'assets/images/icon-play.svg';
import iconAgain from 'assets/images/again.svg';
import iconNew from 'assets/images/newQuiz.svg';
import iconIgen from 'assets/images/igen.svg';
import iconNy from 'assets/images/nyquiz.svg';
import StarRating from 'components/layout/star-rating';
import ShareButtons from 'components/layout/share-buttons';
import { challengeUiTexts } from 'data/ui-texts';
import { IGState, useGlobal } from 'components/game/game-controller';
import './popup.scss';

interface IProps {
	showPopup: boolean;
	type: string;
	title?: string | null;
	text?: string | null;
	text2?: string | undefined | null;
	image?: string | null;
	points?: number | null;
	iconData?: any | null;
	buttons?: any[];
	onClick?: any;
	onClose?: () => void;
	goToPage?: any;
	goToPreviousPage?: any;
	frameText?: boolean;
	roomId?: number;
	roomsCompleted?: { room1: boolean; room2: boolean; room3: boolean; room4: boolean, room5: boolean; room6: boolean };
	allCompleted?: boolean;
	handleResetRoom?: () => void;
}
const Popup: React.FC<IProps> = ({
	showPopup,
	type,
	title = null,
	text = null,
	text2,
	// image = null,
	points = null,
	iconData = null,
	buttons,
	onClick,
	onClose,
	goToPage,
	goToPreviousPage,
	roomId,
	roomsCompleted = { room1: false, room2: false, room3: false, room4: false, room5: false, room6: false, },
	allCompleted = false,
	handleResetRoom,
}: IProps) => {
	// let imageFile = null;
	// if (image && image.length > 0) {
	// 	imageFile = require('assets/images/' + image);
	// }

	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [challengeUiTextsLang, setChallengeUiTextsLang] = React.useState({...challengeUiTexts.dk});
	React.useEffect(() => {
		setChallengeUiTextsLang(currentlang === 'en' ? challengeUiTexts.en : challengeUiTexts.dk );
	}, [currentlang]);
	
	const NewPointTierCard = () => {
		return (
			<div className="Popup-text">
				<div className="Popup-tierbox">
					<p className="Popup-tier">{iconData.title}</p>
					<span className={'Popup-animal ' + iconData.id}></span>
				</div>
			</div>
		);
	};

	const RoomCompletedCard = () => {
		return (
			<>
				<NewPointTierCard />
				<div className={`Ribbon Ribbon-${type} Room${roomId}`}>
					<StarRating roomId={roomId ? roomId : 1} points={points !== null ? points : 0} />
				</div>
			</>
		);
	};

	return (
		<div className={'Popup Popup--' + type + (showPopup ? ' Popup--show' : '')} onClick={onClick ? onClick : null}>
			{type === 'wayfinding' && (
				<div className="Popup-header">
					<BackButton goToPreviousPage={goToPreviousPage} />
				</div>
			)}
			<div className="Popup-body">
				<div className="Popup-wrap">
					{/* Close btn */}
					{onClose && type !== 'wayfinding' && <div className="Popup-close" onClick={onClose} />}

					{/* Title */}
					{title && <div className="Popup-title">{title}</div>}

					{/* Text */}
					{text && type !== 'streak' && <div className="Popup-text" dangerouslySetInnerHTML={{ __html: text }} />}

					{/* Streak / new point tier / room completed */}
					<div className="Popup-innerCard">
						{/* Streak points */}
						{/* {(type === 'streak' && points !== null) && <div className="Popup-points">+{points}p</div>} */}
						{type === 'streak' && points !== null && (
							<div className="Popup-text">{challengeUiTextsLang.newPointTierPopup.text}</div>
						)}
						{/* New point tier */}
						{(type === 'streak' || type === 'newPointsTier') && iconData !== null && <NewPointTierCard />}

						{/* Room completed result */}
						{type === 'roomCompleted' && iconData !== null && <RoomCompletedCard />}
					</div>

					{/* Share btns */}
					{type === 'roomCompleted' && roomId && handleResetRoom && iconData !== null && (
						<>
							<ShareButtons roomId={roomId} pointTierTitle={iconData.title} />
							<div className="Popup-endNav">
								<img
									src={currentlang === 'en' ? iconAgain : iconIgen}
									onClick={() => {
										handleResetRoom();
									}}
									alt="logo"
									className="Popup-iconIgen pointer"
								/>
								<img
									src={currentlang === 'en' ? iconNew : iconNy}
									onClick={() => {
										goToPage('roomOverview');
									}}
									alt="logo"
									className="Popup-iconNy pointer"
								/>
							</div>
						</>
					)}

					{/* Buttons */}
					{buttons && (
						<div className="Popup-buttons">
							{buttons.map((button: any, index: any) => {
								return (
									<React.Fragment key={index}>
										<div
											className="Popup-button pointer"
											onClick={() => {
												button.action(...button.params);
											}}
										>
											{button.text}
										</div>
										<img
											src={iconPlay}
											onClick={() => {
												button.action(...button.params);
											}}
											alt="logo"
											className="Popup-iconPlay pointer"
										/>
									</React.Fragment>
								);
							})}
						</div>
					)}
				</div>
				{type !== 'roomCompleted' && <div className={`Ribbon Ribbon-${type}`}></div>}
			</div>
		</div>
	);
};

export default Popup;
