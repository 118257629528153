import { icons, IIcons } from 'data/highscore-data';
import { IPlayerData } from 'components/game/game-controller';
import { IQuizData } from 'data/quizzes-data';
import { ISortData } from 'data/sort-data';
import { IPairsData } from 'data/pairs-data';
import { IOrderData } from 'data/order-data';

export const getIconType = (
	points: number,
	roomId: number
): IIcons | undefined => {
	const roomIcons = icons.find((rIcons) => {
		return rIcons.roomId === roomId;
	});
	if (roomIcons) {
		const iconType = roomIcons.points.find((icon) => {
			if (
				icon.hasOwnProperty('pointsMin') &&
				icon.hasOwnProperty('pointsMax')
			) {
				if (icon.pointsMin && icon.pointsMax) {
					if (points >= icon.pointsMin && points <= icon.pointsMax) {
						return true;
					}
					return false;
				}
			}
			if (
				icon.hasOwnProperty('pointsMin') &&
				typeof icon.pointsMin === 'number'
			) {
				if (icon.hasOwnProperty('pointsMin') && points >= icon.pointsMin) {
					return true;
				}
			}
			if (
				icon.hasOwnProperty('pointsMax') &&
				typeof icon.pointsMax === 'number'
			) {
				if (icon.hasOwnProperty('pointsMax') && points <= icon.pointsMax) {
					return true;
				}
			}
			return false;
		});
		return iconType;
	}
	return undefined;
};

export const getIconData = (
	points: number,
	roomId: number
): IIcons | undefined => {
	const roomIcons = icons.find((rIcons) => {
		return rIcons.roomId === roomId;
	});
	if (roomIcons) {
		const iconData = roomIcons.points.find((icon) => {
			if (
				icon.hasOwnProperty('pointsMin') &&
				icon.hasOwnProperty('pointsMax') &&
				icon.pointsMin &&
				icon.pointsMax
			) {
				if (points >= icon.pointsMin && points <= icon.pointsMax) {
					return true;
				}
				return false;
			}
			if (icon.pointsMin && !icon.pointsMax) {
				if (icon.hasOwnProperty('pointsMin') && points >= icon.pointsMin) {
					return true;
				}
			}
			if (!icon.pointsMin && icon.pointsMax) {
				if (icon.hasOwnProperty('pointsMax') && points <= icon.pointsMax) {
					return true;
				}
			}
			return false;
		});
		return iconData;
	}
	return undefined;
};

export const checkIfRoomStreakUnlocked = (
	roomChallengesData: IQuizData[] | ISortData[] | IPairsData[] | IOrderData[],
	playerData: IPlayerData
): boolean => {
	let errors = 0;
	roomChallengesData.forEach(
		(challenge: IQuizData | ISortData | IPairsData | IOrderData) => {
			if (errors > 0) return;
			if (
				!playerData.challenges.some((playerChallenge) => {
					return playerChallenge.id === challenge.id;
				})
			) {
				return;
			}
			const playerChallengeData = playerData.challenges.find(
				(playerChallenge) => {
					return playerChallenge.id === challenge.id;
				}
			);
			if ('answers' in challenge) {
				if (challenge.type === 'quiz') {
					let checkIndex = -1;
					const wrongAnswers = challenge.answers.filter((answer) => {
						if (
							playerChallengeData &&
							playerChallengeData.selectedAnswers
						) {
							checkIndex = playerChallengeData.selectedAnswers.indexOf(
								answer.id
							);
						}
						return answer.isCorrect === false && checkIndex >= 0;
					}).length;
					errors = errors + wrongAnswers;
				}
			}
			if (
				challenge.type === 'sort' ||
				challenge.type === 'order' ||
				challenge.type === 'pairs'
			) {
				if (playerChallengeData) {
					errors = errors + playerChallengeData.errors;
				}
			}
		}
	);
	return errors === 0;
};

export const getIconIndex = (points: number, roomId: number): number => {
	let iconIndex = 0;
	const roomIcons = icons.find((rIcons) => {
		return rIcons.roomId === roomId;
	});
	if (roomIcons) {
		roomIcons.points.forEach((icon, i) => {
			if (icon.pointsMin && icon.pointsMax) {
				if (points >= icon.pointsMin && points <= icon.pointsMax) {
					iconIndex = i;
				}
			} else if (icon.pointsMin && !icon.pointsMax) {
				if (points >= icon.pointsMin) {
					iconIndex = 6;
				}
			} else if (!icon.pointsMin && icon.pointsMax) {
				if (points === icon.pointsMax) {
					iconIndex = 0;
				}
			}
		});
	}
	return iconIndex;
};
