import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {loginUiTexts} from 'data/ui-texts';
import StatsLogin from './stats-login';
import StatsController from './stats-controller';

class StatsLoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isLoggingIn: false,
			isLoggedIn: false,
			email: null,
			password: null,
			feedback: null,
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.checkIfLoggedIn();
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	}

	/**
	 * Subscribe to login status
	 */
	checkIfLoggedIn = () => {
		this.setState({isCheckingLogin: true}, () => {

			/* Unsubscribe previous onAuthStateChanged */
			if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
		
			/* Subscribe to onAuthStateChanged */
			this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
				if (user && user.email) {
					/* Logged in as facilitator, check if they have admin access */
					const db = firebase.firestore();
					db.collection('users').doc(user.uid).get().then(() => {
						this.setState({
							isCheckingLogin: false,
							isLoggedIn: true,
							authSessionData: {
								userId: user.uid,
								email: user.email
							}
						});
					}).catch((error) => {
						console.error(error);
						this.setState({isCheckingLogin: false, isLoggedIn: false});
					});
				} else {
					/* Not logged in or invalid login*/
					this.setState({isCheckingLogin: false, isLoggedIn: false});
				}
			});
		});
	}


	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
		});
	}
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		event.preventDefault();
		this.setState({isLoggingIn: true}, () => {

			/* Error: missing fields */
			let email = this.state.email;
			let password = this.state.password;
			if (!email || !password) {
				this.setState({
					isLoggingIn: false,
					feedback: loginUiTexts.someFieldsMissing,
					invalidInputEmail: email ? false : true,
					invalidInputPassword: password ? false : true
				});
				return;
			}

			/* Sign in */
			firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
				this.setState({isLoggingIn: false});
			}).catch((error) => {
				let errorMsg = loginUiTexts.unknownError;
				if (error.code === 'auth/invalid-email') errorMsg = loginUiTexts.invalidEmail;
				if (error.code === 'auth/user-not-found') errorMsg = loginUiTexts.userNotFound;
				if (error.code === 'auth/wrong-password') errorMsg = loginUiTexts.invalidPassword;
				this.setState({feedback: errorMsg, isLoggingIn: false});
			});
		});
	}	

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		/* Login page */
		if (!this.state.isLoggedIn) {
			let feedback = this.state.feedback;
			return (
				<StatsLogin 
					isCheckingLogin={this.state.isCheckingLogin}
					isLoggingIn={this.state.isLoggingIn}
					email={this.state.email}
					password={this.state.password}
					feedback={feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}

		/* Stats page */
		return (
			<StatsController handleLogout={this.handleLogout} />
		);
	}
}

export default StatsLoginController;