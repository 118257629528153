// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import appConfig from 'config/app.config';
import { getDeviceInfo, isMobileOrTablet } from 'helpers/device-helper';
import StatsLoginController from './stats/stats-login-controller';
import GameController from 'components/game/game-controller';
import ConnectionStatus from 'components/connection-status/connection-status';
import './app.scss';


declare global {
	interface Navigator {
		standalone: boolean;
	}
	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>;
		mozRequestFullscreen?: () => Promise<void>;
		webkitRequestFullscreen?: () => Promise<void>;
	}
}

const App: React.FC = () => {
	interface AppState {
		isLoading: boolean;
		isOnline: boolean;
		isInStandaloneMode: boolean;
		isIOS: boolean;
		deviceType: string | null;
	}

	const [appState, setAppState] = useState<AppState>({
		isLoading: true,
		isOnline: true,
		isInStandaloneMode: false,
		isIOS: false,
		deviceType: null,
	});

	/**
	 * Overwrite fontsize from stylesheet if screen is too narrow
	 */
	const handleAdjustFontsize = () => {
		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.getElementsByTagName('body')[0].clientWidth;

		const el = document.getElementById('app');
		const style = el
			? window.getComputedStyle(el).getPropertyValue('font-size')
			: '16px';

		const fontSize = parseFloat(style);
		const maxFontSize = el ? 16 * (width / 360) : 16;
		if (fontSize > 16 * (width / 360) && el) {
			el.style.fontSize = maxFontSize + 'px';
		}
	};

	/**
	 * Toggle fullscreen
	 */

	const handleFullscreen = () => {
		const elem = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullscreen) {
			/* Firefox */
			elem.mozRequestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE/Edge */
			elem.msRequestFullscreen();
		}
	};

	/**
	 * Handle change in network status (online/offline)
	 */
	const handleNetworkChange = () => {
		const isOnline = navigator.onLine;
		if (isOnline !== appState.isOnline) {
			setAppState({
				...appState,
				isLoading: false,
				isOnline,
			});
		}
	};


	useEffect(() => {
		/* Redirect */
		if (window.location.pathname.toLowerCase().includes('/was')) {
			window.location.href = appConfig.availabilityStatementLink;
		}

		/* Initialize firebase */
		firebase.initializeApp(appConfig.firebaseConfig);
		console.log('firebase initialized');
		/* Initialize analytics */
		if (appConfig.env === 'production') {
			firebase.analytics();
			console.log('analytics initialized');
		}

		if (!(window.location.pathname.toLowerCase().indexOf('stats') !== -1)) {
			/* Adjust font size */
			handleAdjustFontsize();
			window.addEventListener('orientationchange', handleAdjustFontsize);

			/* Add network status listeners */
			window.addEventListener('online', handleNetworkChange);
			window.addEventListener('offline', handleNetworkChange);
		}

		/* Check if app is running in stand alone mode */
		const isInStandaloneMode =
			window.navigator.standalone === true ||
			window.matchMedia('(display-mode: standalone)').matches
				? true
				: false;

		/* Get device type */
		const deviceType = isMobileOrTablet() ? 'mobile' : 'desktop';

		/* Is iOS? */
		const isIOS = getDeviceInfo()[2];

		/* Update state */
		setAppState({
			...appState,
			isLoading: false,
			isInStandaloneMode: isInStandaloneMode,
			isIOS: isIOS,
			deviceType: deviceType,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigator.onLine]);

	useEffect(() => {
		return () => {
			window.removeEventListener('online', handleNetworkChange);
			window.removeEventListener('offline', handleNetworkChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	/* Loading */
	if (appState.isLoading) {
		return (
			<div id="app" className="App App--loading">
				<div>Loading ...</div>
			</div>
		);
	}

	/* Stats section */
	if (window.location.pathname.toLowerCase().includes('stats')) {
		return <div id="app" className="App App--stats"><StatsLoginController /></div>;
	}
	
	
	/* Game section */
	return (
		<div id="app" className="App">
			<GameController
				isInStandaloneMode={appState.isInStandaloneMode}
				deviceType={appState.deviceType}
				handleFullscreen={appState.isIOS ? null : handleFullscreen}
			/>
			<a className="App-cookiebotBtn" href="javascript:Cookiebot.renew()">C</a>
			<ConnectionStatus isOnline={appState.isOnline} />
		</div>
	);
};

export default App;
