const roomsData = [
	{
		id: 1,
		name: 'RET OG RETFÆRDIGHED',
		intro: 'Hvad sker der i en retssal, hvad er dine rettigheder, og hvordan er vores (rets)samfund opbygget?',
	},
	{
		id: 2,
		name: 'RETTENS ARBEJDE',
		intro: 'Hvem gør hvad, hvad er en lægdommer, og hvordan straffer vi?',
	},
	{
		id: 3,
		name: 'DET EUROPÆISKE PERSPEKTIV',
		intro:
			'Hvilke domstole er der i Europa, hvilken gyldighed har de, og hvordan har vi indrettet vores retsvæsner forskelligt fra land til land?',
	},
	{
		id: 4,
		name: 'FOR LAW AND JUSTICE',
		intro: 'Start the game!',
	},
	{
		id: 5,
		name: 'CORRUPTION',
		intro: 'What is corruption? What consequences does corruption have, and what measures in the Danish judiciary protect against corruption?',
	},
	{
		id: 6,
		name: 'KORRUPTION',
		intro: 'Hvad er korruption? Hvilke konsekvenser har korruption, og hvilke tiltag i det danske retsvæsen beskytter mod korruption?',
	},
];

export { roomsData };
