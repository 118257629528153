// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import { getCookie, setCookie, deleteCookie } from 'helpers/cookie-helper';
// import useStateCallback from 'helpers/use-state-callback';
// import { errorUiTexts } from 'data/ui-texts';
import LandingPage from 'components/landing-page/landing-page';
import ImageLoader from 'components/image-loader/image-loader';
import About from 'components/about/about';
import RoomOverviewController from 'components/room-overview/room-overview-controller';
import RoomController from 'components/room/room-controller';
import { IResolveStatus } from 'components/challenges/challenge-controller';
import useGlobalHook, { Store } from 'use-global-hook';

export type IGState = {
	currentlang: string;
};

export type IGActions = {
	toggleLang: (currentlang: string) => void;
};

const actions = {
	toggleLang: (store: Store<IGState, IGActions>, currentLang: string) => {
		const lang = currentLang === 'dk' ? 'en' : 'dk';
		store.setState({ ...store.state, currentlang: lang });
	},
};

const initialState = {
	currentlang: 'dk',
};

export const useGlobal = useGlobalHook<IGState, IGActions>(React, initialState, actions);

const gamePages = {
	landingPage: { component: LandingPage },
	about: { component: About },
	roomOverview: { component: RoomOverviewController },
	room: { component: RoomController },
};

interface IProps {
	isInStandaloneMode: boolean;
	deviceType: string | null;
	handleFullscreen: null | (() => void);
}
export interface IPoints {
	room1?: number;
	room2?: number;
	room3?: number;
	room4?: number;
	room5?: number;
	room6?: number;
}
export interface IChallenge {
	orderedItems?: any;
	id: string;
	completed: boolean;
	errors: number;
	sortedItems: string[];
	points: number;
	status?: string;
	roomId: number;
	type?: string;
	selectedAnswers?: number[];
}
export interface IPlayerData {
	created: number;
	challenges: IChallenge[];
	streaks: IPoints;
	completed: any;
	points?: IPoints;
	bonusCompleted: boolean;
	id: string;
}

export interface IPagesComponentProps {
	isInStandaloneMode: any;
	isLoading: any;
	deviceType: any;
	prevPageId: any;
	prevRoomId: any;
	playerData: IPlayerData;
	roomId: any;
	resetGame: () => void;
	goToPage: (pageId: string | null, roomId?: number | null | undefined) => void;
	goToPreviousPage: () => void;
	updatePlayerData: (playerData: IPlayerData) => Promise<IResolveStatus>;
	updateStats: (type: string, roomId: number) => void;
	handleFullscreen: any;
}
const GameController: React.FC<IProps> = ({ isInStandaloneMode, deviceType = 'phone', handleFullscreen }: IProps) => {
	interface GCStateInterface {
		isLoading: boolean;
		initialHistoryLength: number;
		pageId: string | null;
		roomId: number | null;
		prevPageId: string | null;
		prevRoomId: number | null;
		playerData: IPlayerData;
	}

	const defaultPlayerData = {
		created: Math.floor(Date.now() / 1000),
		challenges: [],
		streaks: {},
		points: { room1: 0, room2: 0, room3: 0, room4: 0, room5: 0, room6: 0 },
		completed: null,
		bonusCompleted: false,
		id: 'player1',
	};

	const [gCState, setGCState] = useState<GCStateInterface>({
		isLoading: true,
		initialHistoryLength: 0,
		pageId: 'landingPage',
		roomId: null,
		prevPageId: null,
		prevRoomId: null,
		playerData: defaultPlayerData,
	});

	/**
	 * Clear cookies
	 * @param {string} path
	 */
	const resetGame = () => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		deleteCookie(appConfig.playerDataCookieName);
		window.location.reload();
	};

	/**
	 * Go to page
	 * @param {string} pageId
	 * @param {string} roomId
	 */
	const goToPage = (pageId: string | null, roomId: number | null = null) => {
		/* Scroll to top */
		window.scrollTo(0, 0);

		const prevPageId = gCState.pageId;
		const prevRoomId = gCState.roomId;

		setGCState({ ...gCState, pageId, prevPageId, roomId, prevRoomId });
		if (window.history.length === gCState.initialHistoryLength) {
			window.history.pushState({ page: 2 }, '', '');
		}
		window.history.forward();
	};

	/**
	 * Go to previous page (called when using browser back button)
	 */

	const goToPreviousPage = (triggeredeByEventListener = false) => {
		if (window.history.state.page === 2 && triggeredeByEventListener) {
			return;
		}

		if (gCState.pageId === 'landingPage') return;

		let pageId = null;

		if (gCState.pageId === 'about' || gCState.pageId === 'roomOverview') {
			pageId = 'landingPage';
		}

		if (gCState.pageId === 'room') {
			pageId = 'roomOverview';
		}

		goToPage(pageId, null);
	};

	/**
	 * Update player data
	 * @param {object} updates
	 */
	const updatePlayerData = (playerData: IPlayerData) => {
		// console.log('update player data');
		// const newPlayerDataObj = Object.assign({}, gCState.playerData, playerData);
		// Refactor for IE
		const newPlayerDataObj = [gCState.playerData, playerData].reduce(function (r: any, o: any) {
			Object.keys(o).forEach(function (k) {
				r[k] = o[k];
			});
			return r;
		}, {});

		setCookie(appConfig.playerDataCookieName, JSON.stringify(newPlayerDataObj));
		setGCState({ ...gCState, playerData: newPlayerDataObj });

		return Promise.resolve({ status: 'ok' });
	};

	/**
	 * Update statistics
	 * @param type 
	 * @param roomId 
	 */
	const updateStats = (type: string, roomId: number) => {
		const currentDate = dayjs(new Date()).format('YYYYMMDD');
		const db = firebase.firestore();
		db.collection('stats').add({
			created: currentDate,
			type: type,
			roomId: roomId
		}).catch((error) => {
			console.error(error);
		});
	};

	/**
	 * Refresh game
	 */
	// const handleRefresh = () => {
	// 	// eslint-disable-next-line no-restricted-globals
	// 	location.reload();
	// };

	useEffect(() => {
		window.history.replaceState({ page: 1 }, '', '');

		/* Load cached playerData */
		let playerData = { ...gCState.playerData };
		const cachedPlayerData = getCookie(appConfig.playerDataCookieName);
		if (cachedPlayerData && cachedPlayerData.length > 0) {
			playerData = {
				...defaultPlayerData,
				...JSON.parse(cachedPlayerData),
			};
		}

		/* Update state */
		setGCState({
			...gCState,
			playerData,
			isLoading: false,
			initialHistoryLength: window.history.length,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		/* Listen browser prev/next button events */
		window.addEventListener('popstate', () => {
			goToPreviousPage(true);
		});
	});

	// let Component: React.FC<ComponentProps> = LandingPage;

	// if (gamePages.hasOwnProperty(gCState.pageId)) {
	// 	Component = gamePages[gCState.pageId].component;
	// }

	const props = {
		isInStandaloneMode: isInStandaloneMode,
		isLoading: gCState.isLoading,
		deviceType: deviceType,
		prevPageId: gCState.prevPageId,
		prevRoomId: gCState.prevRoomId,
		playerData: gCState.playerData,
		roomId: gCState.roomId,
		resetGame: resetGame,
		goToPage: goToPage,
		goToPreviousPage: goToPreviousPage,
		updatePlayerData: updatePlayerData,
		handleFullscreen: handleFullscreen,
		updateStats: updateStats
	};
	const pages: any = gamePages;
	if (pages.hasOwnProperty(gCState.pageId) && gCState.pageId) {
		const Component: React.FC<IPagesComponentProps> = pages[gCState.pageId].component;
		return (
			<React.Fragment>
				<Component {...props} />
				<ImageLoader />
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<LandingPage {...props} />
			<ImageLoader />
		</React.Fragment>
	);
};
export default GameController;
