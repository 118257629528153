// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react';
import { renderMarkdown } from 'helpers/text-helper';
import './pairs.scss';
import { IPairsData } from 'data/pairs-data';
import { setBackgroundColor, useWindowSize } from 'helpers/utils';

interface IProps {
	isPaused: boolean;
	pairsData: IPairsData;
	handleSelectCard: (columnId: string, cardIndex: number) => void;
}
const Pairs: React.FC<IProps> = ({
	isPaused,
	pairsData,
	handleSelectCard,
}: IProps) => {
	const numberOfPairsToFind = pairsData.columnA.filter((card) => {
		return (
			card.hasOwnProperty('correctConnectionId') &&
			card.correctConnectionId !== null
		);
	}).length;

	const sizeCheck = useWindowSize();
	const [headerHeight, setHeaderHeight] = useState(80);
	const [itemsHeights, setItemsHeightd] = useState<any[]>([]);
	const [selectedHeights, setSelectedHeights] = useState<number[]>([]);

	useEffect(() => {
		const el = document.getElementById('PairsHeader');
		const height = el ? el.clientHeight + 15 : 80;
		setHeaderHeight(height);

		// Make each pair height to be the same
		const itemsA = document.querySelectorAll('#ColumnA .Pairs-card');
		const itemsB = document.querySelectorAll('#ColumnB .Pairs-card');
		const allItemsHeights: { a: number; b: number }[] = [];
		itemsA.forEach((item, i) => {
			allItemsHeights.push({
				a: itemsA[i].clientHeight,
				b: itemsB[i].clientHeight,
			});
		});
		setItemsHeightd([...allItemsHeights]);
		if (itemsHeights.length > 0) {
			const allHeights: number[] = [];
			itemsHeights.map((item) => {
				if (item.a >= item.b) {
					return allHeights.push(item.a);
				} else if (item.a < item.b) {
					return allHeights.push(item.b);
				}
				return null;
			});
			setSelectedHeights([...allHeights]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sizeCheck]);

	return (
		<div
			className={
				'Pairs Pairs--' + pairsData.id + (isPaused ? ' Pairs--paused' : '')
			}
		>
			{/* Pairs header */}
			<div
				id="PairsHeader"
				className="Pairs-header"
				style={{ backgroundColor: setBackgroundColor(pairsData.roomId) }}
			>
				{/* Question */}
				<div className="Pairs-question">
					{renderMarkdown(pairsData.question.text)}
				</div>
			</div>

			{/* Pairs body */}
			<div
				id="PairsBody"
				className="Pairs-body"
				style={{ paddingTop: `${headerHeight}px` }}
			>
				{/* Column A */}
				<div id="ColumnA" className="Pairs-column Pairs-column--a">
					{pairsData.columnA.map((card, index) => {
						let className = 'pointer Pairs-card';
						if (card.isSelected) {
							className = className + ' selected';
						} else {
							if (card.connectedTo && !pairsData.completed) {
								let status =
									card.connectedTo === card.correctConnectionId
										? 'correct'
										: 'wrong';

								if (
									numberOfPairsToFind === 1 &&
									card.hasOwnProperty('correctConnectionId') &&
									card.correctConnectionId !== null
								) {
									status = 'correct';
								}
								className = className + ' ' + status;
							}
						}
						if (
							pairsData.completed &&
							card.hasOwnProperty('correctConnectionId')
						) {
							className = className + ' correct';
						}

						// const bgImg = '';
						// if (card.image) {
						// 	bgImg = require('../../../assets/images/' + card.image);
						// 	className = className + ' Pairs-card--bgImg';
						// }

						return (
							<div
								key={index}
								className={className}
								// style={bgImg ? { backgroundImage: `url('${bgImg}')` } : {}}
								onClick={() => {
									if (!isPaused) handleSelectCard('columnA', index);
								}}
								style={{
									height: `${selectedHeights[index]}px`,
								}}
							>
								{renderMarkdown(card.text)}
							</div>
						);
					})}
				</div>
				{/* Column B */}
				<div id="ColumnB" className="Pairs-column Pairs-column--b">
					{pairsData.columnB.map((card, index) => {
						let className = 'pointer Pairs-card';
						if (card.isSelected) className = className + ' selected';
						if (
							pairsData.completed &&
							pairsData.columnA.some((cardA) => {
								return cardA.correctConnectionId === card.id;
							})
						) {
							className = className + ' correct';
						}
						if (
							!pairsData.completed &&
							pairsData.columnA.some((cardA) => {
								return cardA.connectedTo === card.id;
							})
						) {
							const cardA = pairsData.columnA.filter((cardA) => {
								return cardA.connectedTo === card.id;
							})[0];
							let status =
								cardA.connectedTo === cardA.correctConnectionId
									? 'correct'
									: 'wrong';
							if (
								numberOfPairsToFind === 1 &&
								pairsData.columnA.some((cardA) => {
									return cardA.correctConnectionId === card.id;
								})
							) {
								status = 'correct';
							}
							className = className + ' ' + status;
						}

						const imgUrl = null;
						// if (card.image) {
						// 	imgUrl = require('../../../assets/images/challenges/' + card.image);
						// 	className = className + ' bgImg';
						// }
						return (
							<div
								key={index}
								className={className}
								onClick={() => {
									if (!isPaused) handleSelectCard('columnB', index);
								}}
								style={{
									height: `${selectedHeights[index]}px`,
								}}
							>
								{imgUrl ? (
									<img src={imgUrl} alt={'shape'} />
								) : (
									renderMarkdown(card.text)
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Pairs;
