// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { errorUiTexts } from 'data/ui-texts';
import { IGState, useGlobal } from 'components/game/game-controller';
import './connection-status.scss';

interface IProps {
	isOnline: boolean;
}
const ConnectionStatus: React.FC<IProps> = ({ isOnline }: IProps) => {

	const [currentlang] = useGlobal<string>((state: IGState) => {	return state.currentlang; },);
	const [errorUiTextsLang, setErrorUiTextsLang] = React.useState({...errorUiTexts.dk});
	React.useEffect(() => {
		setErrorUiTextsLang(currentlang === 'en' ? errorUiTexts.en : errorUiTexts.dk );
	}, [currentlang]);

	return (
		<div
			className={
				'ConnectionStatus' + (!isOnline ? ' ConnectionStatus--offline' : '')
			}
		>
			<div className="ConnectionStatus-text">{errorUiTextsLang.offline}</div>
		</div>
	);
};

export default ConnectionStatus;
